import { Box, MuiThemeProvider } from '@material-ui/core'
import { IsotopeContext, Page, ToolbarContext } from 'isotope-client'
import React from 'react'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { UsersContextProvider } from '../contexts/UsersContext'
import ProfileMenu from '../modules/backOffice/ProfileMenu'
import Users from '../modules/backOffice/users/Users'
import { backTheme } from '../theme'
import JobsPage from 'isotope-client/modules/jobs/JobsPage'
import Countries from '../modules/backOffice/countries/Countries'
import Import from '../modules/backOffice/import/Import'
import RenegotiationHeatmapPage from '../modules/backOffice/renegotiation/RenegotiationHeatmapPage'
import CrmPage from '../modules/backOffice/crm/CrmPage'

const BackOfficeRouter = ({ location }) => {
	return (
		<IsotopeContext.Provider value={{ appName: 'RTV Tool' }}>
			<UsersContextProvider>
				<ToolbarContext.Provider value={{ children: [<ProfileMenu key="profile"/>] }}>
					<MuiThemeProvider theme={backTheme}>
						<Page location={location} menu="rtv">
							<Box pl={5} pr={5}>
								<Switch>
									<Route exact path="/bo/countries" component={Countries} />
									<Route exact path="/bo/renegotiation" component={RenegotiationHeatmapPage} />
									<Route exact path="/bo/users" component={Users} />
									<Route exact path="/bo/jobs" component={JobsPage} />
									<Route exact path="/bo/import" component={Import} />
									<Route exact path="/bo/crm" component={CrmPage} />
								</Switch>
							</Box>
						</Page>
					</MuiThemeProvider>
				</ToolbarContext.Provider>
			</UsersContextProvider>
		</IsotopeContext.Provider>
	)
}

export default withRouter(BackOfficeRouter)