import React, { ReactElement } from 'react'
import { ListItem, ListItemText, MenuItem } from '@material-ui/core'

interface Props {
	key?: string | null
	value: any
	text: string | ReactElement
	selected?: boolean
	style?: Record<string, any>
	handleClick?: () => void
}

const ListItemSelector: React.FunctionComponent<Props> = ({ key, value, text, selected, style, handleClick }) => {
	return (
		<ListItem key={key} button onClick={handleClick} component={MenuItem as any} value={value}>
			<ListItemText
				style={style}
				primaryTypographyProps={selected ? { style: { color: '#447AA8' } } : {}}
				primary={text}
			/>
		</ListItem>
	)
}

export default ListItemSelector
