import { flatten } from 'flat'
import { ACTION_TYPE, CUSTOMER_SATISFACTION, FORECAST_PROBABILITY, MANUALLY_CLOSED_STATUS, CONTRACT_MODULE, VIEW, MONTH } from '../enums'
import { LEVEL } from '../constants'

export default flatten({
	title: 'RTV TOOL',
	menu: {
		retour: 'Back to application',
		countries: 'Manage countries',
		renegotiation: 'Renegotiation heatmap',
		users: 'Manage users',
		import: 'Import users'
	},
	renegotiation : {
		message : 'Adjust each cell based on country sales policy in your local language',
		risk_score: 'Contract risk score',
		price_score: 'Contract price score',
		dialog: {
			title: 'Renegotiation scenario for risk/price score {risk}/{price}',
			tip: 'Tip',
			cancel: 'Cancel',
			update: 'Update'
		}
	},
	searchBar: 'Jump to contract, sales rep...',
	login: {
		button: 'Sign in with Microsoft',
		error: 'Your account does not grant you access to this app'
	},
	layout: {
		dashboard: 'Team Dashboard',
		srOverview: 'Sales Rep. Overview',
		contractsOverview: 'Contracts Overview',
		portfolio: 'My Portfolio',
		actions: 'Actions overview',
		forecasts: 'Forecasts overview',
		reporting: {
			label : 'User reporting',
			teams: 'Teams',
			detailed: 'Monthly Reporting'
		},
		notifications: {
			help: {
				title: 'Help needed',
				noContract: 'No contract needing help',
				needHelp: '{firstName} {lastName} needs help on: {customerName} ({id})'
			},
			overdueContracts: {
				title: 'Overdue contracts',
				noContract: 'No overdue contract',
				contract: '{customerName} ({id}) in priority list since {nbMonths} months'
			},
			overdueActions: {
				title: 'Overdue actions',
				noAction: 'No overdue action',
				action: '{customerName} ({idContract}): action planned on {deadline}'
			}
		},
		logout: 'Logout'
	},
	search: {
		contracts: {
			title: 'Contracts',
			noResult: 'No result found'
		},
		salesRepresentative: {
			title: 'Sales Representatives',
			noResult: 'No result found'
		}
	},
	pages: {
		selectCountry: {
			title: 'Select a country or region',
			indicators: {
				withoutActions: 'Contracts without actions',
				withActions: 'Contracts with actions',
				closed: 'Contracts closed'
			},
			button: 'Team dashboard',
			seeReporting: 'See reporting',
			backToHomePage: 'Back to home page'
		},
		portfolio: {
			title: 'My Portfolio',
			breadcrumbs: 'My Portfolio',
			tabBar: {
				priority: 'Priority List',
				monthly: 'This Month Priority List',
				all: 'All Contracts',
				allDetails: 'All Details'
			}
		},
		contractsOverview: {
			title: 'Contracts Overview',
			breadcrumbs: {
				dashboard: 'Team dashboard',
				contractsOverview: 'Contracts Overview'
			},
			tabBar: {
				priority: 'Priority List',
				cancelled: 'Last Cancelled Contracts',
				all: 'All Contracts'
			},
			filters: {
				title: 'Filters',
				help: 'Select one or multiple items in one or multiple columns to display the contracts matching your selection.',
				reset: 'Reset Filters',
				apply: 'Apply',
				headers: {
					status: 'Contract status',
					classification: 'Classification',
					risk: 'Risk indicator',
					value: 'Value indicator',
					flags: 'Flags',
					endDate: 'Contract end date',
					riskTypology: 'Risk typology',
					customerFeedback: 'Customer feedback'
				},
				status: {
					toDo: 'NO ACTION',
					inProgress: 'IN PROGRESS',
					toBeCancelled: 'TO BE CANCELLED',
					toBeExtended: 'TO BE EXTENDED',
					renegociated: 'RENEGOTIATED',
					upgraded: 'UPGRADED',
					downgraded: 'DOWNGRADED'
				},
				classification: {
					high: 'High value/risk',
					low: 'Low value',
					manually: 'Manually added'
				},
				risk: {
					5: '5 - High',
					4: '4 - Medium',
					3: '3 - Medium',
					2: '2 - Low',
					1: '1 - Low'
				},
				value: {
					5: '5 - High',
					4: '4 - Medium',
					3: '3 - Medium',
					2: '2 - Low',
					1: '1 - Low',
					0: '0 - Negative'
				},
				flags: {
					help: 'Need help',
					overdue: 'Overdue',
					actionsOverdue: 'Actions overdue'
				},
				endDate: {
					outdated: 'Passed',
					short: '< 3 months',
					medium: '3 - 6 months',
					long: '> 6 months'
				},
				riskTypology: {
					field: 'Field',
					sales: 'Sales'
				},
				customerFeedback: {
					cancellationLetter: 'Cancellation letter received',
					claimLetter: 'Claim letter received (12 months)'
				}
			},
			sorts: {
				title: 'Sort by',
				priority: {
					label: 'Priority (default)',
					labelShort: 'Priority'
				},
				risk: {
					label: 'Risk',
					labelShort: 'Risk'
				},
				value: {
					label: 'Value',
					labelShort: 'Value'
				},
				endDate: {
					label: 'Contract end date',
					labelShort: 'End date'
				},
				unitsNumber: {
					label: 'Units number',
					labelShort: 'Units'
				}
			},
			table: {
				searchBar: {
					classic: 'Search by SR, customer or contract id',
					[CONTRACT_MODULE.FR]: 'Search by SR, supervisor, mechanic, customer, contract id or address'
				},
				headers: {
					contract: {
						title: 'Contract',
						cancelled: {
							title: 'Cancelled',
							tooltip: 'Contract cancelled in CRM'
						},
						needHelp: {
							title: 'Need help'
						}
					},
					status: {
						title: 'Action Status',
						notPriority: {
							title: 'Not priority contract',
							tooltip: 'To manage actions on this contract, see contract\'s details and use Add to priotity list action'
						}
					},
					actionsOpened: 'Actions Opened',
					valueIndicator: {
						title: 'Value',
						tooltip: 'Value indicator : {indicator}/5 (0 = negative value)'
					},
					riskIndicator: {
						title: 'Risk',
						tooltip: 'Risk indicator : {indicator}/5'
					},
					riskTypology: {
						title: 'Risk Typology',
						flag: {
							sales: 'Sales flags details',
							field: 'Field flags details'
						}
					},
					endDate: {
						title: 'Contract end date',
						tooltip: {
							desktop: 'Priority Contract not closed after 3 months',
							mobile: 'Contract still opened',
							contractEndTypology: 'Contract end type :',
							requestedCancellationDate: 'Cancellation date requested :'
						}
					},
					unitsNumber: {
						title: 'Units',
						tooltip: 'Selling price :'
					},
					advancedNoticePeriodDate: 'Advanced Notice Period Deadline',
				},
				noResult: 'No contracts found',
				loading: 'Loading contracts',
				show: 'Show contract'
			},
			otherContract: {
				title: 'Other contracts',
				contracts: 'Contracts',
				salesRep: 'Sales Rep.',
				portfolio: 'My Portfolio'
			},
			otherSR: {
				title: 'Other Sales Rep',
				noResult: 'No other sales rep. found',
				headers: {
					sales: 'Sales Rep',
					contracts: 'Contracts'
				}
			}
		},
		salePortfolioManager: {
			breadcrumbs: {
				dashboardRegional: 'Region dashboard',
				dashboard: 'Team dashboard',
				srOverview: 'SR overview',
				srPortfolio: '{firstName} {lastName}\'s portfolio',
				portfolio: 'Portfolio'
			},
			title: {
				srPortfolio: '{firstName} {lastName}\'s portfolio',
				portfolio: 'Portfolio'
			}
		},
		contract: {
			comparison: {
				hide: ' Hide',
				show: ' Show',
				text: 'pricing comparison among similar contracts',
				tooltip: 'Gives indication of contract position vs other contracts with similar characteristics (unit number, contract & customer type...) in Netherlands portfolio.',
				chart: {
					price: 'Contract price positionning',
					value: 'Contract value positionning'
				},
				portfolioValue: 'Growth of portfolio (current year) : '
			},
			risk: {
				close: {
					title: 'Close risk manually',
					outcome: {
						title: 'Outcome',
						to_be_autorenewed: {
							label: 'To be extended',
							detail: '(Contract will be automatically renewed)'
						},
						to_be_cancelled: {
							label: 'To be cancelled',
							detail: '(No possibility to save the contract and will be cancelled)'
						},
						renegotiated: {
							label: 'Renegotiation completed',
							detail: '(Renegotiated with stable contract value)'
						},
						upgraded: {
							label: 'Upselling completed',
							detail: '(Renegotiated with increased contract value)'
						},
						downgraded: {
							label: 'Downselling completed',
							detail: '(Renegotiated with decreased contract value)'
						}
					},
					comment: 'Comment',
					cancel: 'Cancel',
					button : 'Close risk manually',
					errors: {
						required: 'Required',
						mandatory: 'Mandatory information'
					}
				}
			},
			charts: {
				range: 'Range: {rangeMin} {currency} - {rangeMax} {currency}',
				median: 'Median: {median} {currency}'
			},
			flags: {
				description :{
					ContractAge: "Contract Age: Contract is in top 20% oldest contract",
					ContractDuration: "Contract not auto renewed: Contracts with end date < 6 months",
					Public: "Public contract: Customer is public",
					CustomerType: "Type of customer: Customer keen on renegotiation",
					NationalAccount: "National Account: Customer is a National Account",
					Recovery: "Originally recapture contract : Contract initially recaptured from competition",
					SellingPrice: "High price/unit: Contract is in top 20% highest price per unit",
					ContractValue: "High value/unit: Contract is in  top 20% highest value per unit",
					Units: "High # of units: Contract is in the top 20% number of units",
					Rebel: "High ratio of rebel units: Contract is in top 20% rebel units in last 12 months",
					Callbacks: "High # of callbacks/unit: Contract is in top 20% Callbacks/unit in last 12 months",
					ResponseTime: "High average response time: Contract is in top 20% average response time per unit in last 12 months",
					Availability: "Lower availability/unit: Contract is in top 20% lower availibility/unit in last 12 months",
					MissedMaintenance: "Missed Maintenance: Contract is in top 20%  number of missed maintenance in last 12 months",
					Trapped: "Calls for Trapped Passengers: Contract is in top 20% number of calls for trapped passengers in last 12 months"
				},
				close: 'Close'
			},
			breadcrumbs: {
				dashboardRegional: 'Region dashboard',
				dashboard: 'Team dashboard',
				srOverview: 'SR overview',
				srPortfolio: '{firstName} {lastName}\'s portfolio',
				contract: '{name} ({contractId})',
				myPortfolio: 'My Portfolio'
			},
			actions: {
				close: 'Close Risk',
				add: 'Add to priority list',
				help: {
					title: 'Manager\'s Help',
					tooltip: 'Ask for help from the branch manager'
				}
			},
			information: {
				title: 'Information & Risk management',
				status: {
					TODO: 'NO ACTION',
					IN_PROGRESS: 'IN PROGRESS',
					TO_BE_CANCELLED: 'TO BE CANCELLED',
					TO_BE_AUTO_RENEWED: 'TO BE EXTENDED',
					RENEGOTIATED: 'RENEGOTIATED',
					UPGRADED: 'UPGRADED',
					DOWNGRADED: 'DOWNGRADED',
					CLOSED_AUTOMATICALLY: 'TO BE EXTENDED'
				},
				headers: {
					contractValue: {
						title: 'Contract Value',
						tooltip: 'O annual billing value + average T sales < 5K€ in last 3 years'
					},
					customerIdCrm: "Customer number",
					value: 'Value',
					riskTypology: {
						title: 'Risk typology',
						flags: {
							sales: {
								title: 'Sales flags details',
								name: 'Sales'
							},
							field: {
								title: 'Field flags details',
								name: ' Field'
							},
						}
					},
					units: 'Units',
					price: {
						title: 'Maintenance contract price',
						tooltip: 'O annual billing price'
					},
					risk: 'Risk',
					callbacks: 'Callbacks',
					endDate: 'Contract end date',
					invoicesValue: 'Open invoices value',
					address: 'Site address',
					renegotiationTip: {
						header: 'Reneg. principle',
						dialogTitle : 'Renegotiation principle for this contract',
						noTip: 'This contract has no billing and is certainly cancelled',
						outlier: 'This contract is very specific and needs an approval from management in case of renegotiation'
					}
				},
				closed: {
					button: 'Re-open risk',
					text: 'Risk closed on {date} by {firstName} {lastName}',
					textAuto: 'Risk automatically closed on {date}'
				},
				comment: 'Comment',
				dialog: {
					error: {
						title: 'Risk can\'t be closed',
						text: 'All actions must be closed before closing the risk.',
						close: 'Close'
					},
					notAvailable: {
						title: 'Action not available',
						text: 'Only your manager can re-open the risk',
						cancel: 'Close'
					},
					reOpen: {
						title: 'Risk re-opening confirmation',
						text: 'Re-open contract? Closing reason and associated comment will be deleted.',
						confirm: 'Re-open risk'
					},
					addPriority: {
						title: 'Add to priority list',
						text: 'Add this contract to the priority list ?',
						confirm: 'Add contract',
						cancel: 'Close'
					}
				},
				tabs: {
					tab: {
						details: 'Contract details',
						actions: 'Actions',
						zeroAction: 'Create your first action'
					},
					detailsTab: {
						moduleFrance: {
							advancedNoticePeriodDate: 'Advanced Notice Period Deadline',
							contractCategory: 'Contract type',
							requestedCancellationDate: 'Cancellation Date Requested',
							claimLettersNb: 'Number of claim letters received',
							supervisorName: 'Supervisor name',
							mechanicName: 'Mechanic name',
							customerSatisfactionLvl: {
								title: 'Customer satisfaction',
								[CUSTOMER_SATISFACTION.UNSATISFIED]: 'Unsatisfied',
								[CUSTOMER_SATISFACTION.SATISFIED]: 'Satisfied'
							},
							contractEndTypology: 'Type of contract end',
						},
						comments: {
							empty: 'No comment was made',
							lastModified: 'Last modified on {date} by {firstName} {lastName}',
							updateComment: 'Update comment',
							managersTitle: 'General comment by managers',
							srTitle: 'General comment by SR',
							dialog: {
								comment: 'Comment',
								cancel: 'Cancel',
								update: 'Update'
							}
						}
					}
				}
			},
			forecast: {
				title: 'Cancellation Forecast',
				probability: {
					label: 'Probability',
					high: 'High Probability',
					low: 'Low Probability'
				},
				cancellationMonth: {
					label: 'Cancellation month',
				},
				save: 'Save forecast',
				errors: {
					required: 'Required',
					outdated: 'Passed forecast'
				},
				snackbar: {
					success: 'Forecast added',
					error: 'Error in adding'
				}
			}
		},
		dashboard: {
			title: 'Team dashboard - {level}',
			branch: {
				table: {
					titleNational: 'Country open contracts',
					titleRegion: 'Region open contracts',
					headers: {
						branchName: 'Branch',
						todo: 'Open contracts without actions',
						inProgressCount: 'Open contracts with actions',
						closedCount: 'Closed contracts'
					},
					noResult: 'No branches found'
				}
			},
			region: {
				table: {
					title: 'Regions open contracts',
					headers: {
						regionName: 'Region',
						todo: 'Open contracts without actions',
						inProgressCount: 'Open contracts with actions',
						closedCount: 'Closed contracts'
					},
					noResult: 'No regions found'
				}
			},
			highRisk: {
				table: {
					title: 'High value / High risk contracts',
					headers: {
						contract: 'Contract',
						actionsOpened: 'Actions Opened',
						valueIndicator: 'Value',
						riskIndicator: 'Risk'
					},
					tooltip: {
						value: 'Value indicator : {indicator}/5 (0 = negative value)',
						risk: 'Risk indicator : {indicator}/5'
					},
					noResult: 'No contracts found'
				},
				button : 'Show all contracts (monthly meeting)'
			},
			priorityOpenContracts: {
				title: "Priority open contracts",
				indicators: {
					srNeedHelp: 'Sales Representative needs help',
					contractionWithActionsOverdue: 'Contracts with actions overdue',
					contractsOverdue: 'Contracts overdue'
				},
				button: 'Show all contracts',
				contracts: 'Contracts'
			},
			salesRep: {
				title: {
					national: 'National',
					openContracts: 'open contracts'
				},
				table: {
					headers: {
						lastName: 'Sales Representative',
						todo: 'Open contracts without actions',
						inProgress: 'Open contracts with actions',
						closed: 'Closed contracts'
					},
					noResult: 'No sales representatives found'
				},
				button: 'Show all sales representatives'
			},
			topPriorityContracts: {
				title: 'Top priority Contracts distribution',
				tooltip: '{nb} contracts',
				type: {
					noAction: 'No Action',
					inProgress: 'In Progress',
					manuallyClosed: 'Manually Closed'
				}
			},
			manuallyClosedContracts: {
				title: 'Priority contracts closed',
				tooltip: 'Manually closed status distribution (CRM cancellation status is not taken into account)',
				headers: {
					all: 'All Contracts',
					high: 'High Value/Risk',
					low: 'Low Value'
				},
				rows: {
					[MANUALLY_CLOSED_STATUS.TO_BE_CANCELLED]: {
						title: 'To be cancelled',
						tooltip: 'No possibility to save the contract and will be cancelled'
					},
					[MANUALLY_CLOSED_STATUS.TO_BE_AUTO_RENEWED]: {
						title: 'To be extended',
						tooltip: 'Contract will be automatically renewed'
					},
					[MANUALLY_CLOSED_STATUS.RENEGOTIATED]: {
						title: 'Renegotiation completed',
						tooltip: 'Renegotiated contract with stable contract value'
					},
					[MANUALLY_CLOSED_STATUS.UPGRADED]: {
						title:'Upselling completed',
						tooltip: 'Renegotiated contracts with increased contract value'
					},
					[MANUALLY_CLOSED_STATUS.DOWNGRADED]: {
						title: 'Downselling completed',
						tooltip: 'Renegotiated contracts with decreased contract value'
					}
				}
			}
		},
		srPortfolio: {
			openActions: {
				title: '{count} Open Actions on my priority contracts',
				plannedActions: {
					title: 'Planned',
					tooltip: '{count} planned actions'
				},
				overdueActions: {
					title: 'Overdue',
					tooltip: '{count} overdue actions'
				},
				button: 'Show all actions'
			},
			priorityContracts: {
				title: 'My Priority Open contracts',
				noActionPlanned: 'Contracts with no actions planned',
				highValue: 'High Value / Risk',
				lowValue: 'Low Value',
				manually: 'Manually Added',
				nbContracts: '{nb} Contracts'
			},
			followUp: {
				title: 'Contracts follow up',
				openActions: {
					title: '{count} actions',
					planned: {
						title: 'Planned',
						tooltip: '{count} planned actions'
					},
					overdue: {
						title: 'Overdue',
						tooltip: '{count} overdue actions'
					}
				},
				forecasts: {
					title: 'Cancellation Forecast',
					high: {
						title: 'HP',
						tooltip: '{count} HP'
					},
					low: {
						title: 'LP',
						tooltip: '{count} LP'
					}
				},
				button: 'Show all'
			},
		},
		srOverview: {
			title: {
				mobile: 'Sales Rep. Overview',
				desktop: 'Sales Rep. Overview (priority contracts)'
			},
			breadcrumbs: {
				dashboard: 'Team Dashboard',
				srOverview: 'Sales representatives overview'
			},
			row: {
				title: '{openContracts} open contracts, {totalContracts} total ({openContractsPercentage}%)',
				subtitle: '{nbActions} without actions'
			},
			table: {
				headers: {
					lastName: 'Sales',
					openContractsCount: {
						title: 'Open contracts',
						text: '{nbContracts} without actions'
					},
					openActionsCount: {
						title: 'Remaining Actions',
						text: '{nbActions} actions overdue'
					},
					overdueCount: 'Contracts overdue',
					toBeCancelled: 'To be cancelled',
					toBeAutoRenewed: 'To be extended',
					renegotiated: 'Renegotiated',
					upgraded: 'Upgraded',
					downgraded: 'Downgraded',
					openActions: {
						title: 'Open Actions',
						text: '{nbActions} overdue'
					},
					overdueContracts: 'Overdue Contracts'
				},
				noResult: 'No sales representative found'
			}
		},
        reporting: {
	        month: {
		        [MONTH.JANUARY]: 'January',
		        [MONTH.FEBRUARY]: 'February',
		        [MONTH.MARCH]: 'March',
		        [MONTH.APRIL]: 'April',
		        [MONTH.MAY]: 'May',
		        [MONTH.JUNE]: 'June',
		        [MONTH.JULY]: 'July',
		        [MONTH.AUGUST]: 'August',
		        [MONTH.SEPTEMBER]: 'September',
		        [MONTH.OCTOBER]: 'October',
		        [MONTH.NOVEMBER]: 'November',
		        [MONTH.DECEMBER]: 'December'
	        },
	        filters: {
		        level: {
			        all: {
				        [LEVEL.REGION]: 'All regions',
				        [LEVEL.BRANCH]: 'All branches',
				        [LEVEL.SALES_REP]: 'All sales reps',
				        default: 'Perimeter'
			        },
			        [LEVEL.REGION]: 'Regions',
			        [LEVEL.BRANCH]: 'Branches',
			        [LEVEL.SALES_REP]: 'Sales Reps',
			        [LEVEL.USER]: 'Sales Rep',
			        default: 'Perimeter'
		        },
		        year: 'Year',
		        view: {
			        label: 'Display model',
			        [VIEW.MONTHLY]: {
				        performance: 'Monthly view',
				        adoption: 'Monthly view'
			        },
			        [VIEW.YEAR_TO_DATE]: {
				        performance: 'Year To Date',
				        adoption: 'Monthly average'
			        }
		        }
	        },
            tabBar: {
                adoption: 'Adoption',
                activity: 'Activity',
                performance: 'Performance'
            },
	        teams: {
		        title: 'Teams',
		        graph: {
			        performance: {
				        title: '% of contracts saved VS contracts lost',
				        legend : 'YTD %'
			        },
			        activity: {
				        title: '% of contracts with overdue actions',
				        legend : 'YTD %'
			        },
			        adoption: {
				        title: 'Adoption overview',
				        legend : '% of contracts with actions + contracts closed vs total contracts at risk'
			        }
		        }
	        },
	        detailed: {
		        title: 'Monthly Reporting',
		        perimeter: 'Perimeter',
		        graph: {
			        title: 'Contracts saved',
			        value: 'Value',
			        units: 'Units'
		        }
	        },
	        noResult: 'No data found',
	        performance: {
		        table: {
					tooltip : {
						savedContracts : 'Contracts closed as Renegotiated and Extended',
						lostContracts: 'Contracts closed as cancelled',
						savedContractsRate: 'Contracts saved vs contracts lost',
						renegociatedContractsRate: 'Contracts renegotiated vs total of contracts closed',
					},
			        month: 'Month',
			        savedContracts : '<baliseToolTip>Contracts saved {br} (Units/value)</baliseToolTip>',
			        lostContracts: '<baliseToolTip>Contracts lost {br} (Units/value)</baliseToolTip>',
			        savedContractsRate: '<baliseToolTip>Contracts saved rate {br} (Units/value)</baliseToolTip>',
			        renegociatedContracts: 'Contracts renegotiated {br} (Units/value)',
			        renegociatedContractsRate: '<baliseToolTip>Contracts renegotiated rate {br} (Units/value)</baliseToolTip>',
			        total: 'Total'
		        },
		        tooltip : {
			        good: '% of contracts saved > 95%',
			        bad: '% of contracts saved < 80%'
		        }
	        },
	        activity: {
		        table: {
			        day: 'Day',
			        noActionsContracts : 'Contracts without actions {br} (Units/value)',
			        contractsWithActions: 'Contracts with actions {br} (Units/value)',
			        contractsWithActionsRate: 'Contracts % with actions',
			        contractsWithOverdueActionsRate: 'Contracts % with overdue actions',
			        contractsWithHelpRequested: 'Contracts with manager help requested',
			        total: 'Total'
		        },
		        tooltip : 'At least 50% of open contracts should have an action'
	        },
	        adoption: {
		        table: {
			        month: 'Month',
			        nbConnexion : 'Connection to RTV (# of days)',
			        nbChangementsActions: '# of actions updated or modified',
			        nbActionsOuvertes: '# of action opened in the month',
			        nbChangementsDate: '# of actions deadlines changed',
			        nbAjoutContrats: '# of contracts added manually',
			        total: 'Total'
		        },
		        tooltip : 'High number of actions deadlines changed'
	        },
	        level : {
		        [LEVEL.COUNTRY]: 'Countries',
		        [LEVEL.REGION]: 'Regions',
		        [LEVEL.BRANCH]: 'Branches',
		        [LEVEL.SALES_REP]: 'Sales Representatives',
		        [LEVEL.USER]: 'Sales Representative',
	        }
        },
	},
	valueIcon: {
		high: 'High value/high risk priority contract since {month}/{year}',
		low: 'Low value priority contract since {month}/{year}',
		manually: 'Manually added to the priority list'
	},
	router: {
		error: {
			title: 'API connection lost',
			content: 'Please refresh the page or login again.'
		}
	},
	profiles: {
		ZONE_MANAGER: 'EMEA',
		NATIONAL: 'National',
		BRANCH_MANAGER: 'Branch manager',
		SALES_REPRESENTATIVE: 'Sales representative',
		BRANCH_MANAGER_ASSISTANT: 'Branch manager assistant',
		REGIONAL_MANAGER: 'Regional manager'
	},
	actionButton: {
		cancel: 'Cancel'
	},
	language: {
		fr: 'French',
		en: 'English',
		cze: 'Czech',
		de: 'German',
		sp: 'Spanish',
		pt: 'Portugese',
		ita: 'Italian'
	},
	action: {
		filters: {
			all: 'Show All Actions',
			open: 'Show Open Actions',
			closed: 'Show Closed Actions'
		},
		form: {
			select: 'Select action...',
			comparison: 'This contract is too specific for pricing comparison',
			type: 'Action Type',
			deadline: 'Deadline',
			description: 'Description'
		},
		page: {
			title: '{firstName} {lastName}\'s actions overview',
			defaultTitle: 'Actions overview',
			breadcrumbs: {
				dashboard: 'Team dashboard',
				srOverview: 'SR overview',
				repPortfolio: '{firstName} {lastName}\'s Portfolio',
				actionsOverview: 'Actions overview',
				myPortfolio: 'My Portfolio'
			},
			list: {
				noResult: 'No actions found',
				headers: {
					action: 'Action',
					status: 'Status',
					description: 'Description',
					deadline: 'Deadline'
				},
				tooltip: 'Action overdue',
				button: 'Show contract'
			},
			table: {
				searchBar: 'Search by customer, contract id, or description',
				filters: {
					type: 'Action Type...'
				}
			}
		},
		contract: {
			searchBar: 'Search action description',
			addButton: 'Add new action',
			noResult: 'No actions found',
			openBy: 'Opened by {firstName} {lastName}',
			closedOn: 'Closed on {date} by {firstName} {lastName}',
			lastModified: 'Last modified on {date} by {firstName} {lastName}',
			button: {
				update: 'Update action',
				close: 'Close action'
			},
			confirmation: {
				title: 'Action closure confirmation',
				content: 'Close action ?',
				buttonLabel: 'Close action',
			},
			new: {
				title: 'ACTION',
				buttonLabel: 'Create action',
				cancel: 'Cancel'
			},
			update: {
				title: 'ACTION',
				buttonLabel: 'Save'
			},
			deadline: 'Deadline'
		},
		status: {
			all: 'All Actions',
			open: {
				label: 'Open Actions',
				chipLabel: 'Open',
			},
			closed: {
				label: 'Closed Actions',
				chipLabel: 'Closed',
			}
		},
		type: {
			all: {
				label: 'All types',
			},
			[ACTION_TYPE.FIRST_CALL]: {
				label: 'First call',
				defaultDescription: 'Call customer to check satisfaction and context',
			},
			[ACTION_TYPE.CUSTOMER_MEETING]: {
				label: 'Customer meeting',
				defaultDescription: 'Meet customer to improve relationship and check contract context',
			},
			[ACTION_TYPE.REPORT_TO_CUSTOMER]: {
				label: 'Report to customer',
				defaultDescription: 'Share information to customer to improve satisfaction',
			},
			[ACTION_TYPE.PROACTIVE_PROPOSAL]: {
				label: 'Proactive Proposal',
				defaultDescription: 'Make a proposal to renew the contract before the end',
			},
			[ACTION_TYPE.RENEGOTIATION]: {
				label: 'Renegotiation',
				defaultDescription: 'Make a proposal for a new contract requested by customer',
			},
			[ACTION_TYPE.DOWNSELL]: {
				label: 'Downsell',
				defaultDescription: 'Propose to downgrade the contract to remain competitive',
			},
			[ACTION_TYPE.UPSELL]: {
				label: 'Upsell',
				defaultDescription: 'Propose to upgrade contract and price to increase contract value',
			},
			[ACTION_TYPE.SEND_MECHANIC_TO_JOBSITE]: {
				label: 'Send mechanic to jobsite',
				defaultDescription: 'Fix technical issue on the unit',
			},
			[ACTION_TYPE.SEND_SUPERVISOR_TO_JOBSITE]: {
				label: 'Send supervisor to jobsite',
				defaultDescription: 'Audit the unit to avoid callback risk',
			},
			[ACTION_TYPE.CONTACT_SUPERVISOR_MECHANIC]: {
				label: 'Contact supervisor/mechanic',
				defaultDescription: 'Understand technical situation of the unit',
			},
			[ACTION_TYPE.OTHER]: {
				label: 'Other',
			},
			[ACTION_TYPE.CUSTOMER_CLAIM]: {
				label: 'Manage customer claim'
			},
			[ACTION_TYPE.CANCELLATION_REQUEST]: {
				label: 'Manage cancellation request'
			}
		}
	},
	user: {
		new: {
			title: 'User creation',
			buttonLabel: 'Create'
		},
		update: {
			title: 'User modification',
			buttonLabel: 'Update'
		},
		form: {
			errors: {
				required: 'Required',
				invalidFormat: 'Invalid format',
				max255char: 'Maximum 255 characters',
				emailExists: 'This email already exists.',
				emailInactive: 'This email is currently inactive.'
			},
			fields: {
				lastName: 'Last name *',
				firstName: 'First name *',
				email: 'Email *',
				profile: 'Profile',
				country: 'Country',
				branches: 'Branch(es)',
				region: 'Region'
			}
		},
		table: {
			headers: {
				lastName: 'Last name',
				firstName: 'First name',
				email: 'Email',
				branch: 'Branch(es)',
				profile: 'Profile',
				country: 'Country',
				region: 'Region'
			},
			actions: {
				reactivate: 'Reactivate',
				modify: 'Modify',
				deactivate: 'Deactivate'
			},
			filters: {
				searchBar: 'Filter by user name, email or branch',
				select: {
					placeholder: 'Profile',
					all: 'All Profiles',
					national: 'National',
					branchManager: 'Branch Manager',
					branchManagerAssistant: 'Branch Manager Assistant',
					salesRepresentative: 'Sales Representative',
					regionalManager: 'Regional Manager'
				},
				switch: 'Show deactivated users',
				reset: 'RESET'
			}
		},
		menu: {
			myAccount: 'My account',
			quit: 'Quit'
		}
	},
	errors: {
		mandatory: 'Mandatory field',
		onlyFutureDate: 'Only future date'
	},
	countries: {
		table: {
			headers: {
				name: 'Name',
				lowValueThreshold: 'Low value threshold',
				highRiskValueThreshold: 'High risk value threshold',
				lowValueAmount: 'Low value contract amount',
				highRiskValueAmount: 'High risk value contract amount',
				refresh: 'Update on import',
				forecastModule: 'Forecast module'
			},
			actions: {
				modify: 'Modifier'
			},
			values: {
				false: 'No',
				true: 'Yes'
			}
		},
		dialog: {
			title: 'Country modification',
			labels: {
				name: 'Name *',
				lowValueThreshold: 'Low value threshold *',
				highRiskValueThreshold: 'High risk value threshold *',
				lowValueAmount: 'Low value contract amount *',
				highRiskValueAmount: 'High risk value contract amount *',
				refresh: 'Update on import *',
				forecastModule: 'Forecast module *',
				usdConversionRate: 'Conversion rate to $USD'
			},
			values: {
				false: 'No',
				true: 'Yes'
			},
			actions: {
				cancel: 'Cancel',
				update: 'Update'
			},
			errors: {
				required: 'Required',
				negative: 'Value must be greater than 0',
				maximum: 'Value must be lower than 1000'
			}
		}
	},
	forecasts: {
		page: {
			title: '{firstName} {lastName}\'s forecasts overview',
			defaultTitle: 'Forecasts overview',
			breadcrumbs: {
				dashboard: 'Team dashboard',
				srOverview: 'SR overview',
				repPortfolio: '{firstName} {lastName}\'s Portfolio',
				forecastsOverview: 'Forecasts overview',
				myPortfolio: 'My Portfolio'
			},
			list: {
				noResult: 'No forecast found',
				searchBar: 'Search by customer or contract id',
				headers: {
					contract: 'Contract ({contractNumber})',
					status: 'Status',
					units: 'Units ({unitsNumber})',
					unitsEmpty: 'Units',
					probability: 'Forecast',
					cancellationMonth: 'Cancellation Month'
				},
				information: {
					status: {
						TODO: 'NO ACTION',
						IN_PROGRESS: 'IN PROGRESS',
						TO_BE_CANCELLED: 'TO BE CANCELLED',
						TO_BE_AUTO_RENEWED: 'TO BE EXTENDED',
						RENEGOTIATED: 'RENEGOTIATED',
						UPGRADED: 'UPGRADED',
						DOWNGRADED: 'DOWNGRADED'
					},
					cancellationMonth: {
						old: 'Outdated forecast'
					}
				}
			}
		},
		probability: {
			all: {
				label: 'LP + HP',
			},
			[FORECAST_PROBABILITY.LOW]: {
				label: 'LP',
			},
			[FORECAST_PROBABILITY.HIGH]: {
				label: 'HP',
			},
		},
		cancellationMonth: {
			all: {
				label: 'All Months'
			},
			outdated: {
				label: 'Outdated'
			}
		}
	},
	import: {
		form: {
			country: {
				label: 'Country',
				errors: {
					required: 'Required',
				}
			},
			file: {
				label: 'File',
				empty: 'No file',
				button: 'Select a file',
				errors: {
					required: 'Required',
					wrongFormat: 'Wrong file format'
				}
			},
			submit: 'Import'
		},
		results: {
			header: {
				added: 'Number of added users: {count}',
				errors: 'Number of errors: {count}'
			},
			success: 'Added user: {email}',
			errors: {
				regionMissing: 'Region ID missing',
				branchNotInRegion: 'Branch not found in given region',
				branchNotFound: 'Branch not found',
				regionNotFound: 'Region not found',
				branchMissing: 'Branch ID missing',
				profile: 'Profile not found',
				noRegionInCountry: 'No region in this country',
				emailFormat: 'Wrong email format',
				emailMissing: 'Line {line}: Email not found',
				emailMissingExtended: 'Line {line} - {name}: Email not found',
				wrongProfile: 'No match with database profile'
			}
		}
	},
	crm: {
		btn: 'Run contract export to CRM Italy',
		success: 'Success of exporting contract to CRM Italy',
		error: 'Error while exporting contract to CRM Italy',
		loading: 'Exporting contract to CRM Italy...',
		date: {
			title: 'Date',
			helperText: 'Required format : dd-mm-yyyy',
			invalid: 'Invalid format'
		}
	}
})
