import { InputLabel, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Grid from '@material-ui/core/Grid/Grid'
import { FormInput, FormSelect } from 'isotope-client'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { emailRegexp } from '../../../../utils/regexp'
import { LoggedInContext } from '../../../../contexts/LoggedInContext'
import { hasProfile } from '../../../../utils/accessChecker'
import { PROFILE } from '../../../../enums'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import { getCountries, getCountriesWithRegion } from '../../../selectcountry/services/api'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { getCountryBranches } from '../../../branches/branchesApi'
import { OnChange } from 'react-final-form-listeners'
import Typography from '@material-ui/core/Typography/Typography'
import { getCountryRegions } from '../../../regions/api'
import MultiSelect from './MultiSelect'

const styles = (theme) => {
	return {
		tab: {
			display: 'flex',
			width: 'auto',
		},
		datepicker: {
			'& [class*="MuiInputBase-input"]': {
				maxHeight: 25,
				backgroundColor: theme.palette.text.secondary,
			},
		},
		select: {
			'&::before': {
				content: 'unset',
			},
			'&::after': {
				content: 'unset',
			},
		},
		error: {
			color: theme.palette.error.main,
		},
		fields: {
			rowGap: 20
		},
		baseline: {
			alignItems: 'baseline',
			display: 'flex'
		},
		disabled: {
			paddingLeft: 20
		}
	}
}

const Condition = ({ when, is, children }) => (
	<Field name={when} subscription={{ value: true }}>
		{({ input: { value } }) => (value === is ? children : null)}
	</Field>
)

const UserForm = ({ initialValues, onClose, onSubmit, validateButtonLabel, editing, classes, intl, error }) => {
	const { user } = React.useContext(LoggedInContext)

	const [countries, setCountries] = React.useState([])
	const [countriesWithRegion, setCountriesWithRegion] = React.useState([])
	const [regions, setRegions] = React.useState([])
	const [branches, setBranches] = React.useState([])

	React.useEffect(() => {
		getCountries().then(setCountries)
		getCountriesWithRegion().then(setCountriesWithRegion)
	}, [])

	React.useEffect(() => {
		if (editing) {
			if (initialValues.profile === PROFILE.REGIONAL_MANAGER) getCountryRegions(initialValues.countries[0]).then(setRegions)
			if (initialValues.profile === PROFILE.BRANCH_MANAGER_ASSISTANT)
				getCountryBranches(initialValues.countries[0]).then(setBranches)
		}
	}, [editing, initialValues])

	const renderValue = (selected) => {
		return (
			<Typography>
				{selected.map((value, index) => {
					const branch = branches.find((branch) => branch.id === value)
					return branch ? `${branch.name}${index === selected.length - 1 ? '' : ', '}` : ''
				})}
			</Typography>
		)
	}

	const getCountriesOptions = React.useCallback((profile) => profile === PROFILE.REGIONAL_MANAGER ? countriesWithRegion : countries,
		[countries, countriesWithRegion])


	const validate = (values, user, intl) => {
		const errors = {}
		if (!values.lastName) {
			errors.lastName = intl.formatMessage({ id: 'user.form.errors.required' })
		} else if (values.lastName.length > 255) {
			errors.lastName = intl.formatMessage({ id: 'user.form.errors.max255char' })
		}
		if (!values.firstName) {
			errors.firstName = intl.formatMessage({ id: 'user.form.errors.required' })
		} else if (values.firstName.length > 255) {
			errors.firstName = intl.formatMessage({ id: 'user.form.errors.max255char' })
		}
		if (!values.email) {
			errors.email = intl.formatMessage({ id: 'user.form.errors.required' })
		} else if (values.email.length > 255) {
			errors.email = intl.formatMessage({ id: 'user.form.errors.max255char' })
		} else if (!values.email.match(emailRegexp)) {
			errors.email = intl.formatMessage({ id: 'user.form.errors.invalidFormat' })
		}
		if (hasProfile(user, PROFILE.ZONE_MANAGER) && !editing && values.profile === PROFILE.NATIONAL && (!values.countries || values.countries.length === 0)) {
			errors.countries = intl.formatMessage({ id: 'user.form.errors.required' })
		}
		if (hasProfile(user, PROFILE.ZONE_MANAGER) && !editing && values.profile === PROFILE.REGIONAL_MANAGER) {
			if (!values.countries || values.countries.length === 0) {
				errors.countries = intl.formatMessage({ id: 'user.form.errors.required' })
			}
			if (!values.regions) {
				errors.regions = intl.formatMessage({ id: 'user.form.errors.required' })
			}
		}
		if (hasProfile(user, PROFILE.ZONE_MANAGER) && !editing && values.profile === PROFILE.BRANCH_MANAGER_ASSISTANT) {
			if (!values.countries || values.countries.length === 0) {
				errors.countries = intl.formatMessage({ id: 'user.form.errors.required' })
			}
			if (!values.branches || values.branches.length === 0) {
				errors.branches = intl.formatMessage({ id: 'user.form.errors.required' })
			}
		}
		return errors
	}

	return (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={(values) => validate(values, user, intl)}
			render={({ handleSubmit, form, values }) => (
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Field
									name="lastName"
									fullWidth
									component={FormInput}
									label={intl.formatMessage({ id: 'user.form.fields.lastName' })}
									maxLength={255}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="firstName"
									fullWidth
									component={FormInput}
									label={intl.formatMessage({ id: 'user.form.fields.firstName' })}
									maxLength={255}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="email"
									fullWidth
									component={FormInput}
									label={intl.formatMessage({ id: 'user.form.fields.email' })}
									maxLength={255}
								/>
							</Grid>
							<Grid item xs={12} container className={classes.fields}>
								{hasProfile(user, PROFILE.ZONE_MANAGER) ? (
									<>
										{!editing ? (
											<Grid item xs={12}>
												<Field
													name="profile"
													fullWidth
													component={FormSelect}
													inputStyle={classes.select}
													label={intl.formatMessage({ id: 'user.form.fields.profile' })}
												>
													{[
														PROFILE.ZONE_MANAGER,
														PROFILE.NATIONAL,
														PROFILE.REGIONAL_MANAGER,
														PROFILE.BRANCH_MANAGER_ASSISTANT,
													].map((profile, key) => (
														<MenuItem key={key} value={profile}>
															<FormattedMessage id={`profiles.${profile}`} />
														</MenuItem>
													))}
												</Field>
											</Grid>
										) : (
											<Grid item xs={12} className={classes.baseline}>
												<InputLabel shrink id="profile-label">
													<FormattedMessage id="user.form.fields.profile" />
												</InputLabel>
												<div className={classes.disabled}>
													<FormattedMessage id={`profiles.${initialValues.profile}`} />
												</div>
											</Grid>
										)}
										{values.profile !== PROFILE.ZONE_MANAGER && ((editing && values.profile === PROFILE.NATIONAL) || !editing) && (
											<Grid item xs={12}>
												<Field
													name="countries"
													fullWidth
													component={MultiSelect}
													inputStyle={classes.select}
													label={intl.formatMessage({ id: 'user.form.fields.country' })}
												>
													{getCountriesOptions(values.profile).map((country, key) => (
														<MenuItem key={key} value={country.id}>
															{country.name}
														</MenuItem>
													))}
												</Field>
											</Grid>
										)}
										<Condition when="profile" is={PROFILE.REGIONAL_MANAGER}>
											<Grid item xs={12}>
												<Field
													name="regions"
													fullWidth
													component={MultiSelect}
													inputStyle={classes.select}
													label={intl.formatMessage({ id: 'user.form.fields.region' })}
												>
													{regions.map((region, key) => (
														<MenuItem key={key} value={region.id}>
															{region.name}
														</MenuItem>
													))}
												</Field>
											</Grid>
											<OnChange name="countries">
												{(value) => {
													if (value.length > 1) value.pop()
													if (!!value) {
														getCountryRegions(value).then(setRegions)
														form.change('regions', null)
													}
												}}
											</OnChange>
										</Condition>
										<Condition when="profile" is={PROFILE.BRANCH_MANAGER_ASSISTANT}>
											<Grid item xs={12}>
												<Field
													name="branches"
													component={MultiSelect}
													label={intl.formatMessage({ id: 'user.form.fields.branches' })}
													renderValue={renderValue}
												>
													{branches.map((branch, key) => (
														<MenuItem key={key} value={branch.id}>
															{branch.name}
														</MenuItem>
													))}
												</Field>
											</Grid>
											<OnChange name="countries">
												{(value) => {
													if (value.length > 1) value.pop()
													if (!!value) {
														getCountryBranches(value).then(setBranches)
														form.change('branches', [])
													}
												}}
											</OnChange>
										</Condition>
										<OnChange name="profile">
											{() => {
												form.change('countries', [])
												form.change('regions', null)
												form.change('branches', [])
											}}
										</OnChange>
									</>
								) : (
									<Grid item xs={12} className={classes.baseline}>
										<InputLabel shrink id="profile-label">
											<FormattedMessage id="user.form.fields.profile" />
										</InputLabel>
										<div className={classes.disabled}>
											<FormattedMessage id={`profiles.${initialValues.profile}`} />
										</div>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Typography className={classes.error}>{error && <FormattedMessage id={error} />}</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}>Cancel</Button>
						<Button type="submit" variant="contained" size="small" color="primary" disableElevation>
							{validateButtonLabel}
						</Button>
					</DialogActions>
				</form>
			)}
		/>
	)
}

export default compose(withStyles(styles), injectIntl)(UserForm)
