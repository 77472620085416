import { flatten } from 'flat'
import { ACTION_TYPE, CUSTOMER_SATISFACTION, FORECAST_PROBABILITY, MANUALLY_CLOSED_STATUS, CONTRACT_MODULE, VIEW, MONTH } from '../enums'
import { LEVEL } from '../constants'

export default flatten({
    title: 'RTV',
    menu: {
        retour: 'Volver a la aplicación',
        countries: 'Gestión de países',
        renegotiation: 'Mapa de calor de la renegociación',
        users: 'Gestión de usuarios',
        import: 'Importar usuarios'
    },
    renegotiation : {
        message : 'Ajustar cada celda en función de la política de ventas',
        risk_score: 'Puntuación del riesgo del contrato',
        price_score: 'Puntuación del precio del contrato',
        dialog: {
            title: 'Escenario de renegociación para la puntuación de riesgo/precio {risk}/{price}',
            tip: 'Sugerencia',
            cancel: 'Cancelar',
            update: 'Actualizar'
        }
    },
    searchBar: 'Ir a contrato, comercial,…',
    login: {
        button: 'Iniciar sesión con Microsoft',
        error: 'No puede acceder a esta aplicación'
    },
    layout: {
        dashboard: 'Panel de control del equipo',
        srOverview: 'Vista general del comercial',
        contractsOverview: 'Vista general de contratos',
        portfolio: 'Mi cartera',
        actions: 'Vista general de acciones',
        forecasts: 'Vista general del forecast',
        reporting: {
            label: 'Informe de usuario',
            teams: 'Equipos',
            detailed: 'Informe mensual'
        },
        notifications: {
            help: {
                title: 'Necesito ayuda',
                noContract: 'Sin contrato que necesite ayuda',
                needHelp: '{firstName} {lastName} necesita ayuda con: {customerName} ({id})'
            },
            overdueContracts: {
                title: 'Contrato atrasado',
                noContract: 'No hay contratos atrasados',
                contract: '{customerName} ({id}) en lista prioritaria desde {nbMonths} meses'
            },
            overdueActions: {
                title: 'Acciones atrasadas',
                noAction: 'No hay acciones atrasadas',
                action: '{customerName} ({idContract}): acción planificada para {deadline}'
            }
        },
        logout: 'Cerrar sesión'
    },
    search: {
        contracts: {
            title: 'Contratos',
            noResult: 'No se encontró ningún resultado'
        },
        salesRepresentative: {
            title: 'Comerciales',
            noResult: 'No se encontró ningún resultado'
        }
    },
    pages: {
        selectCountry: {
            title: 'Seleccione un país o área',
            indicators: {
                withoutActions: 'Contratos sin acciones',
                withActions: 'Contratos con acciones',
                closed: 'Contratos cerrados'
            },
            button: 'Panel de control del equipo',
            seeReporting: 'Ver informe',
            backToHomePage: 'Volver a página de inicio'
        },
        portfolio: {
            title: 'Mi cartera',
            breadcrumbs: 'Mi cartera',
            tabBar: {
                priority: 'Lista prioritaria',
                monthly: 'Lista prioritaria del mes actual',
                all: 'Todos los contratos',
                allDetails: 'Todos los detalles'
            }
        },
        contractsOverview: {
            title: 'Vista general de contratos',
            breadcrumbs: {
                dashboard: 'Panel de control del equipo',
                contractsOverview: 'Vista general de contratos'
            },
            tabBar: {
                priority: 'Lista prioritaria',
                cancelled: 'Últimos contratos de baja',
                all: 'Todos los contratos'
            },
            filters: {
                title: 'Filtros',
                help: 'Seleccione uno o varios elementos de una o varias columnas para mostrar los contratos que concuerdan con su selección',
                reset: 'Limpiar filtros',
                apply: 'Aplicar',
                headers: {
                    status: 'Estado del contrato',
                    classification: 'Clasificación',
                    risk: 'Indicador del riesgo',
                    value: 'Indicador del valor',
                    flags: 'Indicadores',
                    endDate: 'Fecha fin del contrato',
                    riskTypology: 'Tipo de riesgo',
                    customerFeedback: 'Feedback del cliente'
                },
                status: {
                    toDo: 'Sin acciones',
                    inProgress: 'En progreso',
                    toBeCancelled: 'Para dar de baja',
                    toBeExtended: 'Para prorrogar',
                    renegociated: 'Renegociado',
                    upgraded: 'Realizado upgrading',
                    downgraded: 'Realizado downgrading'
                },
                classification: {
                    high: 'Alto valor / riesgo',
                    low: 'Bajo valor',
                    manually: 'Añadido manualmente'
                },
                risk: {
                    1: '1 - Bajo',
                    2: '2 - Bajo',
                    3: '3 - Medio',
                    4: '4 - Medio',
                    5: '5 - Alto'
                },
                value: {
                    0: '0 - Negativo',
                    1: '1 - Bajo',
                    2: '2 - Bajo',
                    3: '3 - Medio',
                    4: '4 - Medio',
                    5: '5 - Alto'
                },
                flags: {
                    help: 'Necesito ayuda',
                    overdue: 'Atrasado',
                    actionsOverdue: 'Acciones atrasadas'
                },
                endDate: {
                    outdated: 'Aprobado',
                    short: '< 3 meses',
                    medium: '3 - 6 meses',
                    long: '> 6 meses'
                },
                riskTypology: {
                    field: 'Obras',
                    sales: 'Ventas'
                },
                customerFeedback: {
                    cancellationLetter: 'Recibida carta de baja',
                    claimLetter: 'Recibida reclamación (12 meses)'
                }
            },
            sorts: {
                title: 'Ordenar por',
                priority: {
                    label: 'Prioridad (por defecto)',
                    labelShort: 'Prioridad'
                },
                risk: {
                    label: 'Riesgo',
                    labelShort: 'Riesgo'
                },
                value: {
                    label: 'Valor',
                    labelShort: 'Valor'
                },
                endDate: {
                    label: 'Fecha fin del contrato',
                    labelShort: 'Fecha fin'
                },
                unitsNumber: {
                    label: 'Número de unidades',
                    labelShort: 'Unidades'
                }
            },
            table: {
                searchBar: {
                    classic: 'Buscar por comercial, cliente o contrato',
                    [CONTRACT_MODULE.FR]: 'Buscar por comercial, supervisor, técnico, cliente, contrato o dirección'
                },
                headers: {
                    contract: {
                        title: 'Contrato',
                        cancelled: {
                            title: 'De baja',
                            tooltip: 'Contrato de baja en CRM'
                        },
                        needHelp: {
                            title: 'Necesito ayuda'
                        }
                    },
                    status: {
                        title: 'Estado de la acción',
                        notPriority: {
                            title: 'Contrato no prioritario',
                            tooltip: 'Para realizar acciones en este contrato, ver los detalles del contrato y usar la acción \'Añadir a lista prioritaria\''
                        }
                    },
                    actionsOpened: 'Acciones pendientes',
                    valueIndicator: {
                        title: 'Valor',
                        tooltip: 'Indicador del valor: {indicator}/5 (0 = valor negativo)'
                    },
                    riskIndicator: {
                        title: 'Riesgo',
                        tooltip: 'Indicador del riesgo: {indicator}/5'
                    },
                    riskTypology: {
                        title: 'Tipo de riesgo',
                        flag: {
                            sales: 'Indicadores de Ventas',
                            field: 'Indicadores de Obras'
                        }
                    },
                    endDate: {
                        title: 'Fecha fin del contrato',
                        tooltip: {
                            desktop: 'Contrato prioritario sin cerrar después de 3 meses',
                            mobile: 'Contrato continúa pendiente',
                            contractEndTypology: 'Tipo de finalización del contrato:',
                            requestedCancellationDate: 'Fecha de baja requerida:'
                        }
                    },
                    unitsNumber: {
                        title: 'Unidades',
                        tooltip: 'Precio de venta:'
                    },
                    advancedNoticePeriodDate: 'Fecha límite del período del preaviso'
                },
                noResult: 'No se encontratron contratos',
                loading: 'Cargando contratos',
                show: 'Mostrar contrato'
            },
            otherContract: {
                title: 'Otros contratos',
                contracts: 'Contratos',
                salesRep: 'Comercial ',
                portfolio: 'Mi cartera'
            },
            otherSR: {
                title: 'Otro comercial',
                noResult: 'No se encontró ningún otro comercial',
                headers: {
                    sales: 'Comercial',
                    contracts: 'Contratos'
                }
            }
        },
        salePortfolioManager: {
            breadcrumbs: {
                dashboardRegional: 'Panel de control del área',
                dashboard: 'Panel de control del equipo',
                srOverview: 'Vista general del comercial',
                srPortfolio: 'Cartera de {firstName} {lastName}',
                portfolio: 'Cartera'
            },
            title: {
                srPortfolio: 'Cartera de {firstName} {lastName}',
                portfolio: 'Cartera'
            }
        },
        contract: {
            comparison: {
                hide: 'Ocultar',
                show: 'Mostrar',
                text: 'Comparar precios con contratos similares',
                tooltip: 'Indica posición del contrato en comparación con otros contratos de similares características (número de unidades, tipo de cliente o de contrato,…) en la cartera',
                chart: {
                    price: 'Posicionar el precio del contrato',
                    value: 'Posicionar el valor del contrato'
                },
                portfolioValue: 'Crecimiento de la cartera (año actual):'
            },
            risk: {
                close: {
                    title: 'Cerrar el riesgo manualmente',
                    outcome: {
                        title: 'Resultado',
                        to_be_autorenewed: {
                            label: 'Para prorrogar',
                            detail: '(El contrato será renovado automáticamente)'
                        },
                        to_be_cancelled: {
                            label: 'Para dar de baja',
                            detail: '(No hay posibilidad de evitar la baja del contrato y será cancelado)'
                        },
                        renegotiated: {
                            label: 'Renegociación completada',
                            detail: '(Renegociado manteniendo el valor del contrato)'
                        },
                        upgraded: {
                            label: 'Upgrading completado',
                            detail: '(Renegociado con aumento del valor del contrato)'
                        },
                        downgraded: {
                            label: 'Downgrading completado',
                            detail: '(Renegociado con disminución del valor del contrato)'
                        }

                    },
                    comment: 'Comentario',
                    cancel: 'Cancelar',
                    button: 'Cerrar el riesgo manualmente',
                    errors: {
                        required: 'Requerido',
                        mandatory: 'Información obligatoria'
                    }
                }
            },
            charts: {
                range: 'Rango: {rangeMin} {currency} - {rangeMax} {currency}',
                median: 'Mediana: {median} {currency}'
            },
            flags: {
                description: {
                    ContractAge: 'Antigüedad del contrato: el contrato está dentro del 20% de los contratos más antiguos',
                    ContractDuration: 'Contrato no autorrenovable: contratos con fecha de finalización < 6 meses',
                    Public: 'Contrato Público: el cliente es una Administración Pública',
                    CustomerType: 'Tipo de cliente: cliente interesado en renegociar',
                    NationalAccount: 'Cliente Centralizado: cliente de Grandes Cuentas',
                    Recovery: 'Originalmente captación de contrato: contrato inicialmente captado de la competencia',
                    SellingPrice: 'Alto precio/unidad: el contrato está dentro del 20% de los contratos con más alto precio por unidad',
                    ContractValue: 'Alto valor/unidad: el contrato está dentro del 20% de los contratos con más alto valor por unidad',
                    Units: 'Alto número de unidades: el contrato está dentro del 20% de los contratos con mayor número de unidades',
                    Rebel: 'Alto ratio de unidades rebeldes: el contrato está dentro del 20% de los contratos con mayor número de unidades rebeldes en los últimos 12 meses',
                    Callbacks: 'Alto número de avisos/unidad: el contrato está dentro del 20% de los contratos con mayor número de avisos por unidad en los últimos 12 meses',
                    ResponseTime: 'Alto tiempo de respuesta medio: el contrato está dentro del 20% de los contratos con mayor tiempo de respuesta medio por unidad en los últimos 12 meses',
                    Availability: 'Baja disponibilidad/unidad: el contrato está dentro del 20% de los contratos con más baja disponibilidad por unidad en los últimos 12 meses',
                    MissedMaintenance: 'Mantenimiento sin realizar: el contrato está dentro del 20% de los contratos con mayor número de preventivos sin realizar en los últimos 12 meses',
                    Trapped: 'Avisos de pasajero atrapado: el contrato está dentro del 20% de los contratos con mayor número de avisos de pasajero atrapado en los últimos 12 meses'
                },
                close: 'Cerrar'
            },
            breadcrumbs: {
                dashboardRegional: 'Panel de control del área',
                dashboard: 'Panel de control del equipo',
                srOverview: 'Vista general del comercial',
                srPortfolio: 'Cartera de {firstName} {lastName}',
                contract: '{name} ({contractId})',
                myPortfolio: 'Mi cartera'
            },
            actions: {
                close: 'Cerrar riesgo',
                add: 'Añadir a lista prioritaria',
                help: {
                    title: 'Manager\'s Help',
                    tooltip: 'Pedir ayuda al delegado'
                }
            },
            information: {
                title: 'Información y gestión del riesgo',
                status: {
                    TODO: 'Sin acciones',
                    IN_PROGRESS: 'En progreso',
                    TO_BE_CANCELLED: 'Para dar de baja',
                    TO_BE_AUTO_RENEWED: 'Para prorrogar',
                    RENEGOTIATED: 'Renegociado',
                    UPGRADED: 'Realizado upgrading',
                    DOWNGRADED: 'Realizado downgrading',
                    CLOSED_AUTOMATICALLY: 'Para prorrogar'
                },
                headers: {
                    contractValue: {
                        title: 'Valor de contrato',
                        tooltip: 'Importe de facturación mensual de la O + Ventas medias de T < 5K€, en los últimos 3 años'
                    },
                    customerIdCrm: "Número de cliente",
                    value: 'Valor',
                    riskTypology: {
                        title: 'Tipo de riesgo',
                        flags: {
                            sales: {
                                title: 'Indicadores de Ventas',
                                name: 'Ventas'
                            }, field: {
                                title: 'Indicadores de Obras',
                                name: 'Obras'
                            }
                        }
                    },
                    units: 'Unidades',
                    price: {
                        title: 'Precio contrato de mantenimiento',
                        tooltip: 'Importe de facturación anual de la O '
                    },
                    risk: 'Riesgo',
                    callbacks: 'Avisos',
                    endDate: 'Fecha fin del contrato',
                    invoicesValue: 'Valor de las facturas pendientes',
                    address: 'Dirección web',
                    renegotiationTip: {
                        header: 'Criterio de la renegociación',
                        dialogTitle : 'Criterio de la renegociación para este contrato',
                        noTip: 'Este contrato no tiene facturación y se dará de baja',
                        outlier: 'Este contrato es muy específico y necesita aprobación del manager para renegociarlo'
                    }
                },
                closed: {
                    button: 'Reabrir riesgo',
                    text: 'Riesgo cerrado el {date} por {firstName} {lastName}',
                    textAuto: 'Riesgo cerrado automáticamente el {date}'
                },
                comment: 'Comentario',
                dialog: {
                    error: {
                        title: 'El riesgo no puede ser cerrado',
                        text: 'Todas las acciones deben ser cerradas antes de cerrar el riesgo',
                        close: 'Cerrar'
                    },
                    notAvailable: {
                        title: 'Acción no disponible',
                        text: 'Sólo tu manager puede reabrir el riesgo',
                        cancel: 'Cerrar'
                    },
                    reOpen: {
                        title: 'Confirmación de riesgo reabierto',
                        text: '¿Reabrir contrato? El motivo del cierre y el comentario asociado serán eliminados',
                        confirm: 'Reabrir riesgo'
                    },
                    addPriority: {
                        title: 'Añadir a lista prioritaria',
                        text: '¿Añadir este contrato a la lista prioritaria?',
                        confirm: 'Añadir contrato',
                        cancel: 'Cerrar'
                    }
                },
                tabs: {
                    tab: {
                        details: 'Detalles del contrato',
                        actions: 'Acciones',
                        zeroAction: 'Crear tu primera acción'
                    },
                    detailsTab: {
                        moduleFrance: {
                            advancedNoticePeriodDate: 'Fecha límite del período del preaviso',
                            contractCategory: 'Tipo de contrato',
                            requestedCancellationDate: 'Fecha de baja requerida',
                            claimLettersNb: 'Número de reclamaciones recibidas',
                            supervisorName: 'Nombre del supervisor',
                            mechanicName: 'Nombre del técnico',
                            customerSatisfactionLvl: {
                                title: 'Satisfacción del cliente',
                                [CUSTOMER_SATISFACTION.UNSATISFIED]: 'Insatisfecho',
                                [CUSTOMER_SATISFACTION.SATISFIED]: 'Satisfecho'
                            },
                            contractEndTypology: 'Tipo de finalización del contrato'
                        },
                        comments: {
                            empty: 'No se ha hecho ningún comentario',
                            lastModified: 'Modificado por última vez el {date} por {firstName} {lastName}',
                            updateComment: 'Actualizar comentario',
                            managersTitle: 'Comentario general del manager',
                            srTitle: 'Comentario general del comercial',
                            dialog: {
                                comment: 'Comentario',
                                cancel: 'Cancelar',
                                update: 'Actualizar'
                            }
                        }
                    }
                }
            },
            forecast: {
                title: 'Forecast de baja',
                probability: {
                    label: 'Probabilidad',
                    high: 'Alta probabilidad',
                    low: 'Baja probabilidad'
                },
                cancellationMonth: {
                    label: 'Mes de la baja'
                },
                save: 'Guardar forecast',
                errors: {
                    required: 'Requerido',
                    outdated: 'Forecast aprobado'
                },
                snackbar: {
                    success: 'Forecast añadido',
                    error: 'Error al añadir'
                }
            }
        },
        dashboard: {
            title: 'Panel de control del equipo - {level}',
            branch: {
                table: {
                    titleNational: 'Contratos pendientes por Compañía',
                    titleRegion: 'Contratos pendientes por área',
                    headers: {
                        branchName: 'Delegación',
                        todo: 'Contratos pendientes sin acciones',
                        inProgressCount: 'Contratos pendientes con acciones',
                        closedCount: 'Contratos cerrados'
                    },
                    noResult: 'No se encontraron delegaciones'
                }
            },
            region: {
                table: {
                    title: 'Contratos pendientes por área',
                    headers: {
                        regionName: 'Área',
                        todo: 'Contratos pendientes sin acciones',
                        inProgressCount: 'Contratos pendientes con acciones',
                        closedCount: 'Contratos cerrados'
                    }, noResult: 'No se encontraron áreas'
                }
            }, highRisk: {
                table: {
                    title: 'Contratos de alto valor / alto riesgo',
                    headers: {
                        contract: 'Contrato',
                        actionsOpened: 'Acciones pendientes',
                        valueIndicator: 'Valor',
                        riskIndicator: 'Riesgo'
                    },
                    tooltip: {
                        value: 'Indicador del valor: {indicator}/5 (0 = valor negativo)',
                        risk: 'Indicador del riesgo: {indicator}/5'
                    },
                    noResult: 'No se encontraron contratos'
                },
                button: 'Mostrar todos los contratos (sesión mensual)'
            },
            priorityOpenContracts: {
                title: 'Contratos prioritarios pendientes',
                indicators: {
                    srNeedHelp: 'Comercial necesita ayuda',
                    contractionWithActionsOverdue: 'Contratos con acciones atrasadas',
                    contractsOverdue: 'Contratos atrasados'
                },
                button: 'Montrar todos los contratos',
                contracts: 'Contratos'
            },
            salesRep: {
                title: {
                    national: 'Nacional  ',
                    openContracts: 'Contratos pendientes'
                },
                table: {
                    headers: {
                        lastName: 'Comercial',
                        todo: 'Contratos pendientes sin acciones',
                        inProgress: 'Contratos pendientes con acciones',
                        closed: 'Contratos cerrados'
                    },
                    noResult: 'No se encontraron comerciales'
                },
                button: 'Mostrar todos los comerciales'
            },
            topPriorityContracts: {
                title: 'Distribución de contratos de alta prioridad',
                tooltip: '{nb} contratos',
                type: {
                    noAction: 'Sin acciones',
                    inProgress: 'En progreso',
                    manuallyClosed: 'Cerrado manualmente'
                }
            },
            manuallyClosedContracts: {
                title: 'Contratos prioritarios cerrados',
                tooltip: 'Estado de los contratos cerrados manualmente (el estado de baja en el CRM no se tiene en cuenta)',
                headers: {
                    all: 'Todos los contratos',
                    high: 'Alto valor / riesgo',
                    low: 'Bajo valor'
                },
                rows: {
                    [MANUALLY_CLOSED_STATUS.TO_BE_CANCELLED]: {
                        title: 'Para dar de baja',
                        tooltip: 'No hay posibilidad de evitar la baja del contrato y será cancelado'
                    },
                    [MANUALLY_CLOSED_STATUS.TO_BE_AUTO_RENEWED]: {
                        title: 'Para prorrogar', tooltip: 'El contrato será renovado automáticamente'
                    },
                    [MANUALLY_CLOSED_STATUS.RENEGOTIATED]: {
                        title: 'Renegociación completada',
                        tooltip: 'Contrato renegociado manteniendo el valor del contrato'
                    },
                    [MANUALLY_CLOSED_STATUS.UPGRADED]: {
                        title: 'Upgrading completado',
                        tooltip: 'Contratos renegociados con aumento del valor del contrato'
                    },
                    [MANUALLY_CLOSED_STATUS.DOWNGRADED]: {
                        title: 'Downgrading completado',
                        tooltip: 'Contratos renegociados con disminución del valor del contrato'
                    }
                }
            }
        },
        srPortfolio: {
            openActions: {
                title: '{count} Acciones pendientes en mis contratos prioritarios',
                plannedActions: {
                    title: 'Planificado',
                    tooltip: '{count} acciones planificadas'
                },
                overdueActions: {
                    title: 'Atrasado',
                    tooltip: '{count} acciones atrasadas'
                },
                button: 'Mostrar todas las acciones'
            },
            priorityContracts: {
                title: 'Mis contratos prioritarios pendientes',
                noActionPlanned: 'Contratos sin acciones planificadas',
                highValue: 'Alto valor / riesgo',
                lowValue: 'Bajo valor',
                manually: 'Añadido manualmente',
                nbContracts: '{nb} contratos'
            },
            followUp: {
                title: 'Seguimiento de contratos',
                openActions: {
                    title: '{count} acciones',
                    planned: {
                        title: 'Planificado',
                        tooltip: '{count} acciones planificadas'
                    },
                    overdue: {
                        title: 'Atrasado',
                        tooltip: '{count} acciones atrasadas'
                    }
                },
                forecasts: {
                    title: 'Forecast de baja',
                    high: {
                        title: 'Alta probabilidad',
                        tooltip: '{count} Alta probabilidad'
                    },
                    low: {
                        title: 'Baja probabilidad',
                        tooltip: '{count} Baja probabilidad'
                    }
                },
                button: 'Mostrar todos'
            }
        },
        srOverview: {
            title: {
                mobile: 'Vista general del comercial',
                desktop: 'Vista general del comercial (contratos prioritarios)'
            },
            breadcrumbs: {
                dashboard: 'Panel de control del equipo',
                srOverview: 'Vista general de los comerciales'
            },
            row: {
                title: '{openContracts} contratos pendientes, {totalContracts} total ({openContractsPercentage}%)',
                subtitle: '{nbActions} sin acciones'
            },
            table: {
                headers: {
                    lastName: 'Ventas',
                    openContractsCount: {
                        title: 'Contratos pendientes',
                        text: '{nbContracts} sin acciones'
                    },
                    openActionsCount: {
                        title: 'Acciones restantes',
                        text: '{nbActions} acciones atrasadas'
                    },
                    overdueCount: 'Contratos atrasados',
                    toBeCancelled: 'Para dar de baja',
                    toBeAutoRenewed: 'Para prorrogar',
                    renegotiated: 'Renegociado',
                    upgraded: 'Realizado upgrading',
                    downgraded: 'Realizado downgrading',
                    openActions: {
                        title: 'Acciones pendientes',
                        text: '{nbActions} atrasado'
                    },
                    overdueContracts: 'Contrato atrasado'
                },
                noResult: 'No se encontró el comercial'
            }
        },
        reporting: {
            month: {
                [MONTH.JANUARY]: 'Enero',
                [MONTH.FEBRUARY]: 'Febrero',
                [MONTH.MARCH]: 'Marzo',
                [MONTH.APRIL]: 'Abril',
                [MONTH.MAY]: 'Mayo',
                [MONTH.JUNE]: 'Junio',
                [MONTH.JULY]: 'Julio',
                [MONTH.AUGUST]: 'Agosto',
                [MONTH.SEPTEMBER]: 'Septiembre',
                [MONTH.OCTOBER]: 'Octubre',
                [MONTH.NOVEMBER]: 'Noviembre',
                [MONTH.DECEMBER]: 'Diciembre'
            },
            filters: {
                level: {
                    all: {
                        [LEVEL.REGION]: 'Todas las áreas',
                        [LEVEL.BRANCH]: 'Todas las delegaciones',
                        [LEVEL.SALES_REP]: 'Todos los comerciales',
                        default: 'Perímetro'
                    },
                    [LEVEL.REGION]: 'Áreas',
                    [LEVEL.BRANCH]: 'Delegaciones',
                    [LEVEL.SALES_REP]: 'Comerciales',
                    [LEVEL.USER]: 'Comercial',
                    default: 'Perímetro'
                },
                year: 'Año',
                view: {
                    label: 'Mostrar modelo',
                    [VIEW.MONTHLY]: {
                        performance: 'Vista mensual',
                        adoption: 'Vista mensual'
                    },
                    [VIEW.YEAR_TO_DATE]: {
                        performance: 'Hasta la fecha',
                        adoption: 'Media mensual'
                    }
                }
            },
            tabBar: {
                adoption: 'Uso',
                activity: 'Actividad',
                performance: 'Desempeño'
            },
            teams: {
                title: 'Equipos',
                graph: {
                    performance: {
                        title: '% de contratos con baja evitada vs contratos perdidos',
                        legend: '% YTD'
                    },
                    activity: {
                        title: '% de contratos con acciones atrasadas',
                        legend: '% YTD'
                    },
                    adoption: {
                        title: 'Vista general de uso',
                        legend: '% de contratos con acciones + contratos cerrados vs contratos totales en riesgo'
                    }
                }
            },
            detailed: {
                title: 'Informe mensual',
                perimeter: 'Perímetro',
                graph: {
                    title: 'Contratos con baja evitada',
                    value: 'Valor',
                    units: 'Unidades'
                }
            },
            noResult: 'No se encontraron datos',
            performance: {
                table: {
                    tooltip: {
                        savedContracts: 'Contratos cerrados como renegociados y prorrogados',
                        lostContracts: 'Contratos cerrados como baja',
                        savedContractsRate: 'Contratos con baja evitada vs contratos perdidos',
                        renegociatedContractsRate: 'Contratos renegociados vs contratos totales cerrados'
                    },
                    month: 'Mes',
                    savedContracts: '<baliseToolTip>Contratos con baja evitada {br} (Unidades/valor)</baliseToolTip>',
                    lostContracts: '<baliseToolTip>Contratos perdidos {br} (Unidades/valor)</baliseToolTip>',
                    savedContractsRate: '<baliseToolTip>Ratio de contratos con baja evitada {br} (Unidades/valor)</baliseToolTip>',
                    renegociatedContracts: 'Contratos renegociados {br} (Unidades/valor)',
                    renegociatedContractsRate: '<baliseToolTip>Ratio de contratos renegociados {br} (Unidades/valor)</baliseToolTip>',
                    total: 'Total'
                },
                tooltip: {
                    good: '% de contratos con baja evitada > 95%',
                    bad: '% de contratos con baja evitada < 80%'
                }
            },
            activity: {
                table: {
                    day: 'Día',
                    noActionsContracts: 'Contratos sin acciones {br} (Unidades/valor)',
                    contractsWithActions: 'Contratos con acciones {br} (Unidades/valor)',
                    contractsWithActionsRate: '% de contratos con acciones',
                    contractsWithOverdueActionsRate: '% de contratos con acciones atrasadas',
                    contractsWithHelpRequested: 'Contratos con ayuda requerida al manager',
                    total: 'Total'
                },
                tooltip: 'Al menos el 50% de los contratos pendientes deberían tener una acción'
            },
            adoption: {
                table: {
                    month: 'Mes',
                    nbConnexion: 'Conexión a RTV (# de días)',
                    nbChangementsActions: '# de acciones actualizadas o modificadas',
                    nbActionsOuvertes: '# de acciones pendientes en el mes',
                    nbChangementsDate: '# de fechas límite de acciones modificadas',
                    nbAjoutContrats: '# de contratos añadidos manualmente',
                    total: 'Total'
                },
                tooltip: 'Alto número de fechas límite de acciones modificadas'
            }, level: {
                [LEVEL.COUNTRY]: 'Países',
                [LEVEL.REGION]: 'Áreas',
                [LEVEL.BRANCH]: 'Delegaciones',
                [LEVEL.SALES_REP]: 'Comerciales',
                [LEVEL.USER]: 'Comercial'
            }
        }
    },
    valueIcon: {
        high: 'Contrato prioritario de alto valor/alto riesgo desde {month}/{year}',
        low: 'Contrato prioritario de bajo valor desde {month}/{year}',
        manually: 'Añadido manualmente a la lista prioritaria'
    },
    router: {
        error: {
            title: 'Se perdió la conexión',
            content: 'Por favor, actualice la página o vuelva a iniciar sesión'
        }
    },
    profiles: {
        ZONE_MANAGER: 'EMEA',
        NATIONAL: 'Nacional',
        BRANCH_MANAGER: 'Delegado',
        SALES_REPRESENTATIVE: 'Comercial',
        BRANCH_MANAGER_ASSISTANT: 'Branch manager assistant',
        REGIONAL_MANAGER: 'Regional manager'
    },
    actionButton: {
        cancel: 'Cancelar'
    },
    language: {
        fr: 'Francés',
        en: 'Inglés',
        cze: 'Checo',
        de: 'Alemán',
        sp: 'Español',
        pt: 'Portugués',
        ita: 'Italiano'
    }, action: {
        filters: {
            all: 'Mostrar todas las acciones',
            open: 'Mostrar acciones pendientes',
            closed: 'Mostrar acciones cerradas'
        },
        form: {
            select: 'Seleccione acción…',
            comparison: 'Este contrato es demasiado peculiar para una comparación de precio', type: 'Tipo de acción', deadline: 'Fecha límite', description: 'Descripción'
        }, page: { title: 'Vista general de acciones de {firstName} {lastName}', defaultTitle: 'Vista general de acciones', breadcrumbs: { dashboard: 'Panel de control del equipo', srOverview: 'Vista general del comercial', repPortfolio: 'Cartera de {firstName} {lastName}', actionsOverview: 'Vista general de acciones', myPortfolio: 'Mi cartera' }, list: { noResult: 'No se encontraron acciones', headers: { action: 'Acción', status: 'Estado', description: 'Descripción', deadline: 'Fecha límite' }, tooltip: 'Acción atrasada', button: 'Mostrar contrato' }, table: { searchBar: 'Buscar por cliente, contrato o descripción', filters: { type: 'Tipo de acción…' } } }, contract: { searchBar: 'Buscar descripción de la acción', addButton: 'Añadir nueva acción', noResult: 'No se encontraron acciones', openBy: 'Pendiente por {firstName} {lastName}', closedOn: 'Cerrado el {date} por {firstName} {lastName}', lastModified: 'Modificado por última vez el {date} por {firstName} {lastName}', button: { update: 'Actualizar acción', close: 'Cerrar acción' }, confirmation: { title: 'Confirmación del cierre de la acción', content: '¿Cerrar acción?', buttonLabel: 'Cerrar acción' }, new: { title: 'Acción', buttonLabel: 'Crear acción', cancel: 'Cancelar' }, update: { title: 'Acción', buttonLabel: 'Guardar' }, deadline: 'Fecha límite' }, status: { all: 'Todas las acciones', open: { label: 'Acciones pendientes', chipLabel: 'Pendiente' }, closed: { label: 'Acciones cerradas', chipLabel: 'Cerrado' } }, type: {
            all: {
                label: 'Todos los tipos'
            },
            [ACTION_TYPE.FIRST_CALL]: {
                label: 'Primera llamada',
                defaultDescription: 'Llamar al cliente para comprobar su nivel de satisfacción y el contexto'
            },
            [ACTION_TYPE.CUSTOMER_MEETING]: {
                label: 'Reunión con el cliente',
                defaultDescription: 'Reunirse con el cliente para mejorar la relación y recabar información '
            },
            [ACTION_TYPE.REPORT_TO_CUSTOMER]: {
                label: 'Información al cliente',
                defaultDescription: 'Compartir información con el cliente para mejorar su satisfacción'
            },
            [ACTION_TYPE.PROACTIVE_PROPOSAL]: {
                label: 'Oferta proactiva',
                defaultDescription: 'Hacer una oferta para renovar el contrato antes de su término'
            },
            [ACTION_TYPE.RENEGOTIATION]: {
                label: 'Renegociación',
                defaultDescription: 'Hacer una oferta para renovar el contrato a requerimiento del cliente'
            },
            [ACTION_TYPE.DOWNSELL]: {
                label: 'Downgrading',
                defaultDescription: 'Proponer disminuir el tipo de servicio del contrato para mantenerlo en un precio competitivo'
            },
            [ACTION_TYPE.UPSELL]: {
                label: 'Upgrading',
                defaultDescription: 'Proponer aumentar el tipo de servicio del contrato para incrementar el valor del contrato'
            },
            [ACTION_TYPE.SEND_MECHANIC_TO_JOBSITE]: {
                label: 'Envío del técnico a la instalación',
                defaultDescription: 'Arreglar incidencia técnica en la unidad'
            },
            [ACTION_TYPE.SEND_SUPERVISOR_TO_JOBSITE]: {
                label: 'Envío del supervisor a la instalación',
                defaultDescription: 'Auditar la unidad para evitar el riesgo de un aviso'
            },
            [ACTION_TYPE.CONTACT_SUPERVISOR_MECHANIC]: {
                label: 'Contacto con el supervisor/técnico',
                defaultDescription: 'Entender la situación técnica de la unidad'
            },
            [ACTION_TYPE.OTHER]: {
                label: 'Otros'
            },
            [ACTION_TYPE.CUSTOMER_CLAIM]: {
                label: 'Gestionar reclamación del cliente'
            },
            [ACTION_TYPE.CANCELLATION_REQUEST]: {
                label: 'Gestionar requerimiento de baja'
            }
        }
    },
    user: {
        new: {
            title: 'Creación de usuario',
            buttonLabel: 'Crear'
        },
        update: {
            title: 'Modificación de usuario',
            buttonLabel: 'Actualizar'
        },
        form: {
            errors: {
                required: 'Requerido',
                invalidFormat: 'Formato inválido',
                max255char: 'Máximo 255 caracteres',
                emailExists: 'Este buzón de email ya existe',
                emailInactive: 'Este buzón de email está inactivo en la actualidad'
            },
            fields: {
                lastName: 'Nombre *',
                firstName: 'Apellido *',
                email: 'Email *',
                profile: 'Perfil',
                country: 'País',
                branches: 'Delegación(es)',
                region: 'Área'
            }
        },
        table: {
            headers: {
                lastName: 'Apellido',
                firstName: 'Nombre',
                email: 'Email',
                branch: 'Delegación(es)',
                profile: 'Perfil',
                country: 'País',
                region: 'Área'
            },
            actions: {
                reactivate: 'Reactivar',
                modify: 'Modificar',
                deactivate: 'Desactivar'
            },
            filters: {
                searchBar: 'Filtrar por nombre de usuario, email o delegación',
                select: {
                    placeholder: 'Perfil',
                    all: 'Todos los perfiles',
                    national: 'Nacional',
                    branchManager: 'Delegado',
                    branchManagerAssistant: 'Branch manager assistant',
                    salesRepresentative: 'Comercial',
                    regionalManager: 'Regional manager'
                },
                switch: 'Mostrar usuarios desactivados',
                reset: 'RESET'
            }
        },
        menu: {
            myAccount: 'Mi cuenta',
            quit: 'Salir'
        }
    },
    errors: {
        mandatory: 'Campo obligatorio',
        onlyFutureDate: 'Solo fecha futura'
    }, countries: {
        table: {
            headers: {
                name: 'Nombre',
                lowValueThreshold: 'Umbral de valor bajo',
                highRiskValueThreshold: 'Umbral de alto riesgo',
                lowValueAmount: 'Importe del contrato de valor bajo',
                highRiskValueAmount: 'Importe del contrato de alto riesgo',
                refresh: 'Actualizar la importación',
                forecastModule: 'Modulo del Forecast'
            },
            actions: {
                modify: 'Modificador'
            },
            values: {
                false: 'No',
                true: 'Sí'
            }
        },
        dialog: {
            title: 'Modificación de país',
            labels: {
                name: 'Nombre *',
                lowValueThreshold: 'Umbral de valor bajo *',
                highRiskValueThreshold: 'Umbral de alto riesgo *',
                lowValueAmount: 'Importe del contrato de valor bajo *',
                highRiskValueAmount: 'Importe del contrato de alto riesgo *',
                refresh: 'Actualizar la importación *',
                forecastModule: 'Modulo del Forecast *',
                usdConversionRate: 'Ratio de conversión a $USD'
            },
            values: {
                false: 'No',
                true: 'Sí'
            },
            actions: {
                cancel: 'Cancelar',
                update: 'Actualizar'
            },
            errors: {
                required: 'Requerido',
                negative: 'El valor debe ser mayor que 0',
                maximum: 'El valor debe ser menor que 1000'
            }
        }
    },
    forecasts: {
        page: {
            title: 'Vista general del forecast de {firstName} {lastName}',
            defaultTitle: 'Vista general del forecast',
            breadcrumbs: {
                dashboard: 'Panel de control del equipo',
                srOverview: 'Vista general del comercial',
                repPortfolio: 'Cartera de {firstName} {lastName}',
                forecastsOverview: 'Vista general del forecast',
                myPortfolio: 'Mi cartera'
            }, list: {
                noResult: 'No se encontró el forecast',
                searchBar: 'Buscar por cliente o contrato',
                headers: {
                    contract: 'Contrato ({contractNumber})',
                    status: 'Estado',
                    units: 'Unidades ({unitsNumber})',
                    unitsEmpty: 'Unidades',
                    probability: 'Forecast',
                    cancellationMonth: 'Mes de la baja'
                },
                information: {
                    status: {
                        TODO: 'Sin acciones',
                        IN_PROGRESS: 'En progreso',
                        TO_BE_CANCELLED: 'Para dar de baja',
                        TO_BE_AUTO_RENEWED: 'Para prorrogar',
                        RENEGOTIATED: 'Renegociado',
                        UPGRADED: 'Realizado upgrading',
                        DOWNGRADED: 'Realizado downgrading'
                    }, cancellationMonth: {
                        old: 'Forecast desactualizado'
                    }
                }
            }
        },
        probability: {
            all: {
                label: 'Baja probabilidad + Alta probabilidad'
            },
            [FORECAST_PROBABILITY.LOW]: {
                label: 'Baja probabilidad'
            },
            [FORECAST_PROBABILITY.HIGH]: {
                label: 'Alta probabilidad'
            }
        },
        cancellationMonth: {
            all: {
                label: 'Todos los meses'
            },
            outdated: {
                label: 'Desactualizado'
            }
        }
    },
    import: {
        form: {
            country: {
                label: 'País',
                errors: {
                    required: 'Requerido'
                }
            },
            file: {
                label: 'Fichero',
                empty: 'No hay fichero',
                button: 'Seleccione un fichero',
                errors: {
                    required: 'Requerido',
                    wrongFormat: 'Formato de fichero erróneo'
                }
            },
            submit: 'Importar'
        },
        results: {
            header: {
                added: 'Número de usuarios añadidos: {count}',
                errors: 'Número de errores: {count}'
            },
            success: 'Usuario añadido: {email}',
            errors: {
                regionMissing: 'Falta el área',
                branchNotInRegion:
                    'Delegación no encontrada en el área indicada',
                branchNotFound: 'Delegación no encontrada',
                regionNotFound: 'Área no encontrada',
                branchMissing: 'Falta la delegación',
                profile: 'Perfil no encontrado',
                noRegionInCountry: 'No hay ningún área en este país',
                emailFormat: 'Formato de email erróneo',
                emailMissing: 'Línea {line}: no se encontró el email',
                emailMissingExtended: 'Línea {line} - {name}: no se encontró el email',
                wrongProfile: 'No coincide con el perfil de la base de datos'
            }
        }
    },
    crm: {
        btn: 'Run contract export to CRM Italy',
        success: 'Success of exporting contract to CRM Italy',
        error: 'Error while exporting contract to CRM Italy',
        loading: 'Exporting contract to CRM Italy...',
        date: {
            title: 'Date',
            helperText: 'Required format : dd-mm-yyyy',
            invalid: 'Invalid format'
        }
    }
})