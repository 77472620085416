import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { PROFILE } from '../../../enums'
import { AccessChecker } from '../../../utils/accessChecker'
import { Box, Button } from '@material-ui/core'
import { fetchFactory } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import FormInput from 'isotope-client/components/form/fields/FormInput'

interface FormError {
	dateRefreshCrm?: string
}

interface FormInputType {
	dateRefreshCrm?: string
}

const CrmPage = ({ intl }) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [success, setSuccess] = useState<boolean>(false)

	function onSubmit(values: FormInputType) {
		setLoading(true)
		setError(false)
		setSuccess(false)
		const url = values.dateRefreshCrm ? `/outputcrmitaly/run?dateRefreshCrm=${values.dateRefreshCrm}` : '/outputcrmitaly/run'
		return fetchFactory(url)
			.then(() => setSuccess(true))
			.catch(() => setError(true))
			.finally(() => setLoading(false))
	}

	function validate(values: { dateRefreshCrm?: string }) {
		const errors: FormError = {}
		if (values.dateRefreshCrm) {
			const regexDate = new RegExp('\\d{1,2}-\\d{1,2}-\\d{2,4}')
			if (values.dateRefreshCrm.length !== 0 && !regexDate.test(values.dateRefreshCrm))
			errors.dateRefreshCrm = intl.formatMessage({ id: 'crm.date.invalid' })
		}
		return errors
	}

	return (
		<AccessChecker profiles={[PROFILE.ZONE_MANAGER]}>
			<Box pt={5}>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					render={({handleSubmit}) => (
						<form onSubmit={(event: any) => {
							handleSubmit(event)
						}}>
							<Box width={'25%'}>
								<Field<string>
									name="dateRefreshCrm"
									fullWidth
									component={FormInput}
									inputStyle={{ width: 328 }}
									label={<FormattedMessage id="crm.date.title" />}
								/>
								<FormattedMessage id="crm.date.helperText" />
							</Box>
							<Box position="relative" pt={5}>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									disableElevation
									disabled={loading}
								>
									<FormattedMessage id="crm.btn" />
								</Button>
							</Box>
						</form>
					)}
				/>
				<Box pt={5}>
					{loading && <FormattedMessage id="crm.loading" />}
					{error && <FormattedMessage id="crm.error" />}
					{success && <FormattedMessage id="crm.success" />}
				</Box>
			</Box>
		</AccessChecker>
	)
}

export default injectIntl(CrmPage)