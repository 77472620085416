import { flatten } from 'flat'
import { ACTION_TYPE, CUSTOMER_SATISFACTION, FORECAST_PROBABILITY, MANUALLY_CLOSED_STATUS, CONTRACT_MODULE, VIEW, MONTH } from '../enums'
import { LEVEL } from '../constants'

export default flatten({
	title: 'RTV - Risk to Value',
	menu: {
		retour: 'Voltar  ',
		countries: 'Gestão de países',
		renegotiation: 'Mapa de renegociações',
		users: 'Gestão de utilizadores',
		import: 'Importar utilizadores'
	},
	renegotiation: {
		message: 'Ajustar cada célula com base na política de vendas do país no idioma local',
		risk_score: 'Pontuação de risco do contrato',
		price_score: 'Pontuação do preço do contrato',
		dialog: {
			title: 'Cenário de renegociação para pontuação de risco/preço {risk}/{price}',
			tip: 'Dica',
			cancel: 'Cancelar',
			update: 'Atualizar'
		}
	},
	searchBar: 'Ir para contrato, técnico comercial...',
	login: {
		button: 'Faça login com a Microsoft',
		error: 'A sua conta não lhe dá acesso a esta aplicação'
	},
	layout: {
		dashboard: 'Quadro da equipa',
		srOverview: 'Visão geral dos Técnicos Comerciais',
		contractsOverview: 'Visão geral dos Contratos',
		portfolio: 'O meu Portfolio',
		actions: 'Visão geral das ações',
		forecasts: 'Visão geral do forecast',
		reporting: {
			label: 'Relatório do utilizador',
			teams: 'Equipas',
			detailed: 'Relatórios mensais'
		},
		notifications: {
			help: {
				title: 'Ajuda necessária',
				noContract: 'Sem contratos a necessitar de ajuda',
				needHelp: '{firstName} {lastName} precisa de ajuda em: {customerName} ({id})'
			}, overdueContracts: {
				title: 'Contratos ultrapassados',
				noContract: 'Sem contratos untrapassados',
				contract: '{customerName} ({id}) na lista de prioridades desde {nbMonths} meses'
			},
			overdueActions: {
				title: 'Ações ultrapassadas',
				noAction: 'Sem ações ultrapassadas',
				action: '{customerName} ({idContract}): ação planeada para {deadline}'
			}
		},
		logout: 'Terminar sessão'
	}, search: {
		contracts: {
			title: 'Contratos',
			noResult: 'Nenhum resultado encontrado'
		},
		salesRepresentative: {
			title: 'Técnicos Comerciais',
			noResult: 'Nenhum resultado encontrado'
		}
	}, pages: {
		selectCountry: {
			title: 'Selecione um país ou região',
			indicators: {
				withoutActions: 'Contratos sem ações',
				withActions: 'Contratos com ações',
				closed: 'Contratos fechados'
			},
			button: 'Quadro da equipa',
			seeReporting: 'Ver relatórios',
			backToHomePage: 'Voltar à página inicial'
		},
		portfolio: {
			title: 'O meu Portfolio',
			breadcrumbs: 'O meu Portfolio',
			tabBar: {
				priority: 'Lista de prioridades',
				monthly: 'Lista de prioridades deste mês',
				all: 'Todos os contratos',
				allDetails: 'Todos os detalhes'
			}
		},
		contractsOverview: {
			title: 'Visão geral dos contratos',
			breadcrumbs: {
				dashboard: 'Quadro da equipa',
				contractsOverview: 'Visão geral dos contratos'
			},
			tabBar: {
				priority: 'Lista de prioridades',
				cancelled: 'Últimos contratos cancelados',
				all: 'Todos os contratos'
			},
			filters: {
				title: 'Filtros',
				help: 'Selecione um ou vários itens numa ou várias colunas para exibir os contratos que correspondem à sua seleção.',
				reset: 'Repor filtros',
				apply: 'Aplicar',
				headers: {
					status: 'Estado do contrato',
					classification: 'Classificação',
					risk: 'Indicador de risco',
					value: 'Indicador de valor',
					flags: 'Alertas',
					endDate: 'Data de fim do contrato',
					riskTypology: 'Tipologia de risco',
					customerFeedback: 'Feedback do cliente'
				},
				status: {
					toDo: 'SEM AÇÃO',
					inProgress: 'EM CURSO',
					toBeCancelled: 'PARA SER CANCELADO',
					toBeExtended: 'PARA SER PROLONGADO',
					renegociated: 'RENEGOCIADO',
					upgraded: 'UPGRADE ',
					downgraded: 'DOWNGRADE '
				},
				classification: {
					high: 'Alto valor/risco',
					low: 'Baixo valor',
					manually: 'Adicionado manualmente'
				},
				risk: {
					1: '1 - Baixo',
					2: '2 - Baixo',
					3: '3 - Médio',
					4: '4 - Médio',
					5: '5 - Alto'
				}, value: {
					0: '0 - Negativo',
					1: '1 - Baixo',
					2: '2 - Baixo',
					3: '3 - Médio',
					4: '4 - Médio',
					5: '5 - Alto'
				},
				flags: {
					help: 'Preciso de ajuda',
					overdue: 'Ultrapassado',
					actionsOverdue: 'Ações ultrapassadas'
				},
				endDate: {
					outdated: 'Ultrapassado',
					short: 'menos de 3 meses',
					medium: '3 a 6 meses',
					long: '> 6 meses'
				},
				riskTypology: {
					field: 'Field',
					sales: 'Vendas'
				},
				customerFeedback: {
					cancellationLetter: 'Intenção de cancelamento recebida',
					claimLetter: 'Carta de reclamação recebida (12 meses)'
				}
			},
			sorts: {
				title: 'Ordenar por',
				priority: {
					label: 'Prioridade (padrão)',
					labelShort: 'Prioridade'
				}, risk: {
					label: 'Risco',
					labelShort: 'Risco'
				}, value: {
					label: 'Valor',
					labelShort: 'Valor'
				}, endDate: {
					label: 'Data de fim do contrato',
					labelShort: 'Data de fim'
				}, unitsNumber: {
					label: 'Número de unidades',
					labelShort: 'Unidades'
				}
			},
			table: {
				searchBar: {
					classic: 'Pesquisa por comercial, cliente ou contrato',
					[CONTRACT_MODULE.FR]: 'Pesquisa por comercial, supervisor, técnico, cliente, contrato ou morada'
				},
				headers: {
					contract: {
						title: 'Contrato',
						cancelled: {
							title: 'Cancelado',
							tooltip: 'Contrato cancelado no CRM'
						},
						needHelp: {
							title: 'Preciso de ajuda'
						}
					},
					status: {
						title: 'Estado da ação',
						notPriority: {
							title: 'Não é um contrato prioritário',
							tooltip: '"Para gerir as ações neste contrato, veja os detalhes do contrato e use a opção ""Adicionar"" à lista de prioridades"'
						}
					}, actionsOpened: 'Ações abertas',
					valueIndicator: {
						title: 'Valor',
						tooltip: 'Indicador de valor: {indicator}/5 (0 = valor negativo)'
					}, riskIndicator: {
						title: 'Risco',
						tooltip: 'Indicador de risco: {indicator}/5'
					}, riskTypology: {
						title: 'Tipologia de risco',
						flag: {
							sales: 'Detalhes dos alertas de vendas',
							field: 'Detalhes dos alertas do field'
						}
					},
					endDate: {
						title: 'Data de fim do contrato',
						tooltip: {
							desktop: 'Contrato prioritário não fechado após 3 meses',
							mobile: 'Contrato ainda em aberto',
							contractEndTypology: 'Tipo do contrato final',
							requestedCancellationDate: 'Data de cancelamento solicitada:'
						}
					},
					unitsNumber: {
						title: 'Unidades',
						tooltip: 'Preço de venda:'
					},
					advancedNoticePeriodDate: 'Limite do período de pré aviso'
				},
				noResult: 'Não foram encontrados contratos',
				loading: 'A carregar contratos',
				show: 'Mostrar contrato'
			},
			otherContract: {
				title: 'Outros contratos',
				contracts: 'Contratos',
				salesRep: 'Técnico Comercial',
				portfolio: 'O meu Portfolio'
			},
			otherSR: {
				title: 'Outro Técnico Comercial',
				noResult: 'Nenhum outro técnico comercial encontrado',
				headers: {
					sales: 'Técnico Comercial',
					contracts: 'Contratos'
				}
			}
		},
		salePortfolioManager: {
			breadcrumbs: {
				dashboardRegional: 'Quadro da região',
				dashboard: 'Quadro da equipa',
				srOverview: 'Visão geral do Comercial',
				srPortfolio: '{firstName} {lastName}\'s portfolio',
				portfolio: 'Portfolio'
			},
			title: {
				srPortfolio: '{firstName} {lastName}\'s carteira',
				portfolio: 'Portfolio'
			}
		},
		contract: {
			comparison: {
				hide: 'Ocultar',
				show: 'Mostrar',
				text: 'comparação de preços entre contratos semelhantes',
				tooltip: 'Indica a posição do contrato em relação a outros contratos com caraterísticas semelhantes (número de unidade, contrato e tipo de cliente...).',
				chart: {
					price: 'Posicionamento do preço do contrato',
					value: 'Posicionamento do valor do contrato'
				},
				portfolioValue: 'Crescimento da carteira (ano em curso): '
			},
			risk: {
				close: {
					title: 'Fechar o risco manualmente',
					outcome: {
						title: 'Resultado',
						to_be_autorenewed: {
							label: 'Para ser prolongado',
							detail: '(O contrato será renovado automaticamente)'
						},
						to_be_cancelled: {
							label: 'Para ser cancelado',
							detail: '(Não há possibilidade de reter o contrato e o mesmo será cancelado)'
						},
						renegotiated: {
							label: 'Renegociação concluída',
							detail: '(Renegociado com um valor de contrato estável)'
						},
						upgraded: {
							label: 'Upgrade concluído',
							detail: '(Renegociado com subida do valor do contrato)'
						},
						downgraded: {
							label: 'Downgrade concluído',
							detail: '(Renegociado com diminuição do valor do contrato)'
						}
					},
					comment: 'Comentário',
					cancel: 'Cancelar',
					button: 'Fechar o risco manualmente',
					errors: {
						required: 'Obrigatório',
						mandatory: 'Informação obrigatória'
					}
				}
			}, charts: {
				range: 'Intervalo: {rangeMin} {currency} - {rangeMax} {currency}',
				median: 'Mediana: {median} {currency}'
			},
			flags: {
				description: {
					ContractAge: 'Idade do contrato: O contrato está no top dos 20% mais antigos',
					ContractDuration: 'Contrato não renovado automaticamente: contratos com data de fim < 6 meses',
					Public: 'Contrato público: O cliente é público',
					CustomerType: 'Tipo de cliente: Cliente interessado em renegociação',
					NationalAccount: 'Conta Nacional: O cliente pertence a uma Conta Nacional',
					Recovery: 'Originalmente, recuperação: Contrato inicialmente recuperado da concorrência',
					SellingPrice: 'Preço elevado/unidade: O contrato está no top 20% dos contratos com preço mais elevado por unidade',
					ContractValue: 'Alto valor/unidade: O contrato está no top 20% dos contratos com valor mais elevado por unidade',
					Units: 'Número elevado de unidades: O contrato está no top 20% dos contratos com maior número de unidades',
					Rebel: 'Unidades rebeldes: O contrato está no top 20% das unidades rebeldes nos últimos 12 meses',
					Callbacks: 'Elevado número de avarias: O contrato está no top 20% de contratos com mais avarias/unidade nos últimos 12 meses',
					ResponseTime: 'Tempo médio de resposta elevado: O contrato está no top 20% dos contratos com maior tempo médio de resposta por unidade nos últimos 12 meses',
					Availability: 'Menor disponibilidade/unidade: O contrato está no top 20% dos contratos com menor disponibilidade/unidade nos últimos 12 meses',
					MissedMaintenance: 'Manutenção por realizar: O contrato está no top 20% de contratos com manutenções por realizar nos últimos 12 meses',
					Trapped: 'Passageiros presos: O contrato está no top 20% de contratos com maior número de passageiros presos nos últimos 12 meses'
				},
				close: 'Fechar'
			}, breadcrumbs: {
				dashboardRegional: 'Quadro da região',
				dashboard: 'Quadro da equipa',
				srOverview: 'Visão geral do Comercial',
				srPortfolio: '{firstName} {lastName}\'s portfolio',
				contract: '{name} ({contractId})',
				myPortfolio: 'O meu Portfolio'
			}, actions: {
				close: 'Fechar o risco',
				add: 'Adicionar à lista de prioridades',
				help: {
					title: 'Ajuda do Delegado',
					tooltip: 'Pedir ajuda ao delegado'
				}
			}, information: {
				title: 'Gestão de informação e riscos',
				status: {
					TODO: 'SEM AÇÃO',
					IN_PROGRESS: 'EM CURSO',
					TO_BE_CANCELLED: 'PARA SER CANCELADO',
					TO_BE_AUTO_RENEWED: 'PARA SER PROLONGADO',
					RENEGOTIATED: 'RENEGOCIADO',
					UPGRADED: 'UPGRADE ',
					DOWNGRADED: 'DOWNGRADE',
					CLOSED_AUTOMATICALLY: 'PARA SER PROLONGADO'
				}, headers: {
					contractValue: {
						title: 'Valor do contrato',
						tooltip: 'Valor de faturação anual O + vendas T < 5k€, nos últimos 3 anos'
					},
					customerIdCrm: 'Número de cliente',
					value: 'Valor',
					riskTypology: {
						title: 'Tipologia de risco',
						flags: {
							sales: {
								title: 'Detalhes dos alertas de vendas',
								name: 'Vendas'
							},
							field: {
								title: 'Detalhes dos alertas do field',
								name: 'Field'
							}
						}
					},
					units: 'Unidades',
					price: {
						title: 'Preço do contrato de manutenção',
						tooltip: '"Preço de faturação anual ""O"""'
					},
					risk: 'Risco',
					callbacks: 'Avarias',
					endDate: 'Data de fim do contrato',
					invoicesValue: 'Faturas em ageing',
					address: 'Morada do local',
					renegotiationTip: {
						header: 'Princípio da renegociação',
						dialogTitle: 'Princípio da renegociação para este contrato',
						noTip: 'Este contrato não tem faturação pelo que poderá estar cancelado',
						outlier: 'Este contrato é muito específico e precisa de uma aprovação superior em caso de renegociação'
					}
				},
				closed: {
					button: 'Reabrir o risco',
					text: 'Risco fechado em {date} por {firstName} {lastName}',
					textAuto: 'Risco fechado automaticamente em {date}',
				},
				comment: 'Comentário',
				dialog: {
					error: {
						title: 'O risco não pode ser fechado',
						text: 'Todas as ações devem ser fechadas antes de fechar o risco.',
						close: 'Fechar'
					},
					notAvailable: {
						title: 'Ação não disponível',
						text: 'Apenas o seu delegado pode reabrir o risco',
						cancel: 'Fechar'
					},
					reOpen: {
						title: 'Confirmação de reabertura de risco',
						text: 'Reabrir o contrato? O motivo de encerramento e os comentários associados serão eliminados.',
						confirm: 'Reabrir o risco'
					},
					addPriority: {
						title: 'Adicionar à lista de prioridades',
						text: 'Adicionar este contrato à lista de prioridades?',
						confirm: 'Adicionar contrato',
						cancel: 'Fechar'
					}
				},
				tabs: {
					tab: {
						details: 'Detalhes do contrato',
						actions: 'Ações',
						zeroAction: 'Cria a tua primeira ação'
					},
					detailsTab: {
						moduleFrance: {
							advancedNoticePeriodDate: 'Limite do período de pré aviso',
							contractCategory: 'Tipo de contrato',
							requestedCancellationDate: 'Data de cancelamento solicitada',
							claimLettersNb: 'Número de reclamações recebidas',
							supervisorName: 'Nome do supervisor',
							mechanicName: 'Nome do Técnico',
							customerSatisfactionLvl: {
								title: 'Satisfação do cliente',
								[CUSTOMER_SATISFACTION.UNSATISFIED]: 'Insatisfeito',
								[CUSTOMER_SATISFACTION.SATISFIED]: 'Satisfeito'
							}, contractEndTypology: 'Tipo de fim de contrato'
						}, comments: {
							empty: 'Nenhum comentário foi feito',
							lastModified: 'Última modificação em {date} por {firstName} {lastName}',
							updateComment: 'Atualizar comentário',
							managersTitle: 'Comentário geral do delegado',
							srTitle: 'Comentário geral do comercial',
							dialog: {
								comment: 'Comentário',
								cancel: 'Cancelar',
								update: 'Atualizar'
							}
						}
					}
				}
			}, forecast: {
				title: 'Previsão de cancelamento',
				probability: {
					label: 'Probabilidade',
					high: 'Alta probabilidade',
					low: 'Baixa probabilidade'
				},
				cancellationMonth: {
					label: 'Mês de cancelamento'
				},
				save: 'Guardar previsão',
				errors: {
					required: 'Obrigatório',
					outdated: 'Previsão ultrapassada'
				},
				snackbar: {
					success: 'Previsão adicionada',
					error: 'Erro ao adicionar'
				}
			}
		}, dashboard: {
			title: 'Quadro da equipa - {level}',
			branch: {
				table: {
					titleNational: 'Contratos abertos no país',
					titleRegion: 'Contratos abertos na região',
					headers: {
						branchName: 'Delegação',
						todo: 'Contratos abertos sem ações',
						inProgressCount: 'Contratos abertos com ações',
						closedCount: 'Contratos fechados'
					},
					noResult: 'Não foi encontrada nenhuma delegação'
				}
			},
			region: {
				table: {
					title: 'Contratos abertos nas regiões',
					headers: {
						regionName: 'Região',
						todo: 'Contratos abertos sem ações',
						inProgressCount: 'Contratos abertos com ações',
						closedCount: 'Contratos fechados'
					},
					noResult: 'Não foi encontrada nenhuma delegação'
				}
			},
			highRisk: {
				table: {
					title: 'Contratos de alto valor/alto risco',
					headers: {
						contract: 'Contrato',
						actionsOpened: 'Ações abertas',
						valueIndicator: 'Valor',
						riskIndicator: 'Risco'
					},
					tooltip: {
						value: 'Indicador de valor : {indicator}/5 (0 = valor negativo)',
						risk: 'Indicador de risco : {indicator}/5'
					},
					noResult: 'Não foram encontrados contratos'
				},
				button: 'Mostrar todos os contratos (reunião mensal)'
			}, priorityOpenContracts: {
				title: 'Contratos prioritários abertos',
				indicators: {
					srNeedHelp: 'O técnico comercial precisa de ajuda',
					contractionWithActionsOverdue: 'Contratos com ações ultrapassadas',
					contractsOverdue: 'Contratos ultrapassados'
				},
				button: 'Mostrar todos os contratos',
				contracts: 'Contratos'
			},
			salesRep: {
				title: {
					national: 'Nacional',
					openContracts: 'contratos abertos'
				},
				table: {
					headers: {
						lastName: 'Técnico Comercial',
						todo: 'Contratos abertos sem ações',
						inProgress: 'Contratos abertos com ações',
						closed: 'Contratos fechados'
					},
					noResult: 'Não foram encontrados técnicos comerciais'
				},
				button: 'Mostrar todos os técnicos comerciais'
			},
			topPriorityContracts: {
				title: 'Distribuição dos contratos de prioridade máxima',
				tooltip: '{nb} contratos',
				type: {
					noAction: 'Sem ação',
					inProgress: 'Em curso',
					manuallyClosed: 'Fechado manualmente'
				}
			},
			manuallyClosedContracts: {
				title: 'Contratos prioritários fechados',
				tooltip: 'Distribuição do estado fechada manualmente (o estado de cancelamento do CRM não é tido em conta)',
				headers: {
					all: 'Todos os contratos',
					high: 'Alto valor/risco',
					low: 'Baixo valor'
				},
				rows: {
					[MANUALLY_CLOSED_STATUS.TO_BE_CANCELLED]: {
						title: 'Para ser cancelado',
						tooltip: 'Não há possibilidade de reter o contrato e o mesmo será cancelado'
					},
					[MANUALLY_CLOSED_STATUS.TO_BE_AUTO_RENEWED]: {
						title: 'Para ser prolongado',
						tooltip: 'O contrato será automaticamente renovado'
					},
					[MANUALLY_CLOSED_STATUS.RENEGOTIATED]: {
						title: 'Renegociação concluída',
						tooltip: 'Contrato renegociado com um valor de contrato estável'
					},
					[MANUALLY_CLOSED_STATUS.UPGRADED]: {
						title: 'Upgrade concluído',
						tooltip: 'Contratos renegociados com aumento do valor contratual'
					},
					[MANUALLY_CLOSED_STATUS.DOWNGRADED]: {
						title: 'Downgrade  concluído',
						tooltip: 'Contratos renegociados com diminuição do valor de contrato'
					}
				}
			}
		}, srPortfolio: {
			openActions: {
				title: '{count} Ações abertas nos meus contratos prioritários',
				plannedActions: {
					title: 'Planeado',
					tooltip: '{count} ações planeadas'
				},
				overdueActions: {
					title: 'Ultrapassado',
					tooltip: '{count} ações ultrapassadas'
				},
				button: 'Mostrar todas as ações'
			},
			priorityContracts: {
				title: 'Os meus contratos prioritários em aberto',
				noActionPlanned: 'Contratos sem ações planeadas',
				highValue: 'Alto valor/risco',
				lowValue: 'Baixo valor',
				manually: 'Adicionado manualmente',
				nbContracts: '{nb} Contratos'
			},
			followUp: {
				title: 'Seguimento de contratos',
				openActions: {
					title: '{count} ações',
					planned: {
						title: 'Planeado',
						tooltip: '{count} ações planeadas'
					},
					overdue: {
						title: 'Ultrapassado',
						tooltip: '{count} ações ultrapassadas'
					}
				},
				forecasts: {
					title: 'Previsão de cancelamento',
					high: {
						title: 'Elevada probabilidade',
						tooltip: '{count} Elevada probabilidade'
					},
					low: {
						title: 'Baixa probabilidade',
						tooltip: '{count} Baixa probabilidade'
					}
				},
				button: 'Mostrar tudo'
			}
		},
		srOverview: {
			title: {
				mobile: 'Visão geral dos Técnicos Comerciais',
				desktop: 'Visão geral dos Técnicos Comerciais (contratos prioritários)'
			},
			breadcrumbs: {
				dashboard: 'Quadro da equipa',
				srOverview: 'Visão geral dos Comerciais'
			},
			row: {
				title: '{openContracts} contratos em aberto, {totalContracts} total ({openContractsPercentage}%)',
				subtitle: '{nbActions} sem ações'
			},
			table: {
				headers: {
					lastName: 'Vendas',
					openContractsCount: {
						title: 'Contratos em aberto',
						text: '{nbContracts} sem ações'
					},
					openActionsCount: {
						title: 'Ações restantes',
						text: '{nbActions} ações ultrapassadas'
					},
					overdueCount: 'Contratos ultrapassados',
					toBeCancelled: 'Para ser cancelado',
					toBeAutoRenewed: 'Para ser prolongado',
					renegotiated: 'Renegociado',
					upgraded: 'Upgrade ',
					downgraded: 'Downgrade',
					openActions: {
						title: 'Ações abertas',
						text: '{nbActions} ultrapassado'
					},
					overdueContracts: 'Contratos ultrapassados'
				},
				noResult: 'Nenhum técnico comercial encontrado'
			}
		},
		reporting: {
			month: {
				[MONTH.JANUARY]: 'Janeiro',
				[MONTH.FEBRUARY]: 'Fevereiro',
				[MONTH.MARCH]: 'Março',
				[MONTH.APRIL]: 'Abril',
				[MONTH.MAY]: 'Maio',
				[MONTH.JUNE]: 'Junho',
				[MONTH.JULY]: 'Julho',
				[MONTH.AUGUST]: 'Agosto',
				[MONTH.SEPTEMBER]: 'Setembro',
				[MONTH.OCTOBER]: 'Outubro',
				[MONTH.NOVEMBER]: 'Novembro',
				[MONTH.DECEMBER]: 'Dezembro'
			},
			filters: {
				level: {
					all: {
						[LEVEL.REGION]: 'Todas as regiões',
						[LEVEL.BRANCH]: 'Todas as delegações',
						[LEVEL.SALES_REP]: 'Todos os técnicos comerciais',
						default: 'Área'
					},
					[LEVEL.REGION]: 'Regiões',
					[LEVEL.BRANCH]: 'Delegações',
					[LEVEL.SALES_REP]: 'Técnicos Comerciais',
					[LEVEL.USER]: 'Técnico Comercial',
					default: 'Área'
				},
				year: 'Ano',
				view: {
					label: 'Modelo de apresentação',
					[VIEW.MONTHLY]: {
						performance: 'Vista mensal',
						adoption: 'Vista mensal'
					},
					[VIEW.YEAR_TO_DATE]: {
						performance: 'Até ao dia de hoje',
						adoption: 'Média mensal'
					}
				}
			},
			tabBar: {
				adoption: 'Adoção',
				activity: 'Atividade',
				performance: 'Desempenho'
			},
			teams: {
				title: 'Equipas',
				graph: {
					performance: {
						title: '% de contratos mantidos vs. contratos perdidos',
						legend: 'YTD %'
					},
					activity: {
						title: '% dos contratos com ações ultrapassadas',
						legend: 'YTD %'
					},
					adoption: {
						title: 'Utilização do RTV',
						legend: '% de contratos com ações e contratos fechados versus total de contratos em risco'
					}
				}
			},
			detailed: {
				title: 'Relatórios mensais',
				perimeter: 'Área ',
				graph: {
					title: 'Contratos mantidos',
					value: 'Valor',
					units: 'Unidades'
				}
			}
			, noResult: 'Não foram encontrados dados',
			performance: {
				table: {
					tooltip: {
						savedContracts: 'Contratos fechados como renegociados e prolongados',
						lostContracts: 'Contratos fechados como cancelados',
						savedContractsRate: 'Contratos mantidos vs contratos perdidos',
						renegociatedContractsRate: 'Contratos renegociados vs total de contratos fechados'
					},
					month: 'Mês',
					savedContracts: '<baliseToolTip>Contratos mantidos {br} (Unidades/valor)</baliseToolTip>',
					lostContracts: '<baliseToolTip>Contratos perdidos {br} (Unidades/valor)</baliseToolTip>',
					savedContractsRate: '<baliseToolTip>Taxa de contratos mantido {br} (Units/value)</baliseToolTip>',
					renegociatedContracts: 'Contratos renegociados {br} (Unidades/valor)',
					renegociatedContractsRate: '<baliseToolTip>Taxa de contratos renegociados {br} (Unidades/valor)</baliseToolTip>',
					total: 'Total'
				},
				tooltip: {
					good: '% de contratos mantidos > 95%',
					bad: '% de contratos mantidos < de 80%'
				}
			}, activity: {
				table: {
					day: 'Dia',
					noActionsContracts: 'Contratos sem ações {br} (Unidades/valor)',
					contractsWithActions: 'Contratos com ações {br} (Unidades/valor)',
					contractsWithActionsRate: '% Contratos com ações',
					contractsWithOverdueActionsRate: '% Contratos com ações ultrapassadas',
					contractsWithHelpRequested: 'Contratos em que foi solicitada ajuda do delegado',
					total: 'Total'
				},
				tooltip: 'Pelo menos 50% dos contratos abertos devem ter uma ação'
			},
			adoption: {
				table: {
					month: 'Mês',
					nbConnexion: 'Ligação ao RTV (número de dias)',
					nbChangementsActions: '# de ações atualizadas ou modificadas',
					nbActionsOuvertes: '# de ações abertas no mês',
					nbChangementsDate: '# de ações com alteração do prazo',
					nbAjoutContrats: '# de contratos adicionados manualmente',
					total: 'Total'
				},
				tooltip: 'Elevado número de ações cujo prazo foi alterado'
			},
			level: {
				[LEVEL.COUNTRY]: 'Países',
				[LEVEL.REGION]: 'Regiões',
				[LEVEL.BRANCH]: 'Delegações',
				[LEVEL.SALES_REP]: 'Técnicos Comerciais',
				[LEVEL.USER]: 'Técnico Comercial'
			}
		}
	}, valueIcon: {
		high: 'Contrato prioritário com alto valor/alto risco desde {month}/{year}',
		low: 'Contrato prioritário de baixo valor desde {month}/{year}',
		manually: 'Adicionado manualmente à lista de prioridades'
	},
	router: {
		error: {
			title: 'Ligação à API perdida',
			content: 'Por favor, atualize a página ou faça o login novamente.'
		}
	},
	profiles: {
		ZONE_MANAGER: 'EMEA',
		NATIONAL: 'Nacional',
		BRANCH_MANAGER: 'Delegado',
		SALES_REPRESENTATIVE: 'Técnico Comercial',
		BRANCH_MANAGER_ASSISTANT: 'Administrativa(o)',
		REGIONAL_MANAGER: 'Delegado Regional'
	},
	actionButton: {
		cancel: 'Cancelar'
	},
	language: {
		fr: 'Francês',
		en: 'Inglês',
		cze: 'Checo',
		de: 'Alemão',
		sp: 'Espanhol',
		pt: 'Português',
		ita: "Italiano"
	},
	action: {
		filters: {
			all: 'Mostrar todas as ações',
			open: 'Mostrar ações abertas',
			closed: 'Mostrar ações fechadas'
		},
		form: {
			select: 'Selecionar ação...',
			comparison: 'Este contrato é demasiado específico para comparação de preços',
			type: 'Tipo de ação',
			deadline: 'Prazo',
			description: 'Descrição'
		},
		page: {
			title: '{firstName} {lastName} visão geral das ações',
			defaultTitle: 'Visão geral das ações',
			breadcrumbs: {
				dashboard: 'Quadro da equipa',
				srOverview: 'Visão geral do Comercial',
				repPortfolio: '{firstName} {lastName} Portfolio',
				actionsOverview: 'Visão geral das ações',
				myPortfolio: 'O meu Portfolio'
			}, list: {
				noResult: 'Não foram encontradas ações',
				headers: {
					action: 'Ação',
					status: 'Estado',
					description: 'Descrição',
					deadline: 'Prazo'
				},
				tooltip: 'Ação ultrapassada',
				button: 'Mostrar contrato'
			},
			table: {
				searchBar: 'Pesquisa por cliente, número de contrato ou descrição',
				filters: {
					type: 'Tipo de ação...'
				}
			}
		},
		contract: {
			searchBar: 'Pesquisar a descrição da ação',
			addButton: 'Adicionar nova ação',
			noResult: 'Não foram encontradas ações',
			openBy: 'Aberto por {firstName} {lastName}',
			closedOn: 'Fechado em {date} por {firstName} {lastName}',
			lastModified: 'Última modificação em {date} por {firstName} {lastName}',
			button: {
				update: 'Atualizar a ação',
				close: 'Fechar a ação'
			},
			confirmation: {
				title: 'Confirmação do encerramento da ação',
				content: 'Fechar a ação?',
				buttonLabel: 'Fechar a ação'
			},
			new: {
				title: 'Ação',
				buttonLabel: 'Criar ação',
				cancel: 'Cancelar'
			},
			update: {
				title: 'AÇÃO',
				buttonLabel: 'Guardar'
			},
			deadline: 'Data limite'
		},
		status: {
			all: 'Todas as ações',
			open: {
				label: 'Ações abertas',
				chipLabel: 'Abrir'
			},
			closed: {
				label: 'Ações fechadas',
				chipLabel: 'Fechado'
			}
		},
		type: {
			all: {
				label: 'Todos os tipos'
			},
			[ACTION_TYPE.FIRST_CALL]: {
				label: 'Primeiro contacto',
				defaultDescription: 'Ligar ao cliente para verificar a satisfação e o contexto'
			},
			[ACTION_TYPE.CUSTOMER_MEETING]: {
				label: 'Reunir com o cliente',
				defaultDescription: 'Reunir com o cliente para melhorar o relacionamento e verificar o contexto do contrato'
			},
			[ACTION_TYPE.REPORT_TO_CUSTOMER]: {
				label: 'Comunicar ao cliente',
				defaultDescription: 'Partilhar informações com o cliente para melhorar a satisfação'
			},
			[ACTION_TYPE.PROACTIVE_PROPOSAL]: {
				label: 'Proposta proativa',
				defaultDescription: 'Faça uma proposta para renovar o contrato antes do final'
			},
			[ACTION_TYPE.RENEGOTIATION]: {
				label: 'Renegociação',
				defaultDescription: 'Fazer uma proposta para um novo contrato a pedido do cliente'
			},
			[ACTION_TYPE.DOWNSELL]: {
				label: 'Downgrade',
				defaultDescription: 'Propor downgrade do contrato para se manter competitivo'
			},
			[ACTION_TYPE.UPSELL]: {
				label: 'Upgrade',
				defaultDescription: 'Propor upgrade do contrato para aumentar o valor do mesmo'
			},
			[ACTION_TYPE.SEND_MECHANIC_TO_JOBSITE]: {
				label: 'Pedir ao técnico/mecânico para se deslocar ao local',
				defaultDescription: 'Corrigir problemas técnicos na unidade'
			},
			[ACTION_TYPE.SEND_SUPERVISOR_TO_JOBSITE]: {
				label: 'Pedir ao supervisor para se deslocar ao local',
				defaultDescription: 'Auditar a unidade para evitar avarias'
			},
			[ACTION_TYPE.CONTACT_SUPERVISOR_MECHANIC]: {
				label: 'Contactar o supervisor/técnico',
				defaultDescription: 'Compreender a situação técnica da unidade'
			},
			[ACTION_TYPE.OTHER]: {
				label: 'Outro'
			},
			[ACTION_TYPE.CUSTOMER_CLAIM]: {
				label: 'Gerir a reclamação do cliente'
			},
			[ACTION_TYPE.CANCELLATION_REQUEST]: {
				label: 'Gerir a intenção de cancelamento'
			}
		}
	},
	user: {
		new: {
			title: 'Criar utilizador',
			buttonLabel: 'Criar'
		},
		update: {
			title: 'Modificação do utilizador',
			buttonLabel: 'Atualizar'
		},
		form: {
			errors: {
				required: 'Obrigatório',
				invalidFormat: 'Formato inválido',
				max255char: 'Máximo de 255 carateres',
				emailExists: 'Este e-mail já existe.',
				emailInactive: 'Este e-mail está atualmente inativo.'
			},
			fields: {
				lastName: 'Apelido *',
				firstName: 'Primeiro nome *',
				email: 'E-mail *',
				profile: 'Perfil',
				country: 'País',
				branches: 'Delegação(ões)',
				region: 'Região'
			}
		},
		table: {
			headers: {
				lastName: 'Apelido',
				firstName: 'Primeiro nome',
				email: 'E-mail',
				branch: 'Delegação(ões)',
				profile: 'Perfil',
				country: 'País',
				region: 'Região'
			},
			actions: {
				reactivate: 'Reativar',
				modify: 'Modificar',
				deactivate: 'Desativar'
			},
			filters: {
				searchBar: 'Filtrar por nome de utilizador, e-mail ou delegação',
				select: {
					placeholder: 'Perfil',
					all: 'Todos os perfis',
					national: 'Nacional',
					branchManager: 'Delegado',
					branchManagerAssistant: 'Administrativa(o)',
					salesRepresentative: 'Técnico Comercial',
					regionalManager: 'Delegado Regional'
				},
				switch: 'Mostrar utilizadores desativados',
				reset: 'REPOR'
			}
		},
		menu: {
			myAccount: 'A minha conta', quit: 'Sair'
		}
	}, errors: {
		mandatory: 'Campo obrigatório',
		onlyFutureDate: 'Apenas datas futuras'
	},
	countries: {
		table: {
			headers: {
				name: 'Nome',
				lowValueThreshold: 'Limite de valor baixo',
				highRiskValueThreshold: 'Limiar de alto valor de risco',
				lowValueAmount: 'Valor de contrato baixo',
				highRiskValueAmount: 'Contrato de alto risco/valor',
				refresh: 'Atualizar na importação',
				forecastModule: 'Módulo de Forecast'
			},
			actions: {
				modify: 'Modificador'
			},
			values: {
				false: 'Não',
				true: 'Sim'
			}
		},
		dialog: {
			title: 'Modificação do país',
			labels: {
				name: 'Nome *',
				lowValueThreshold: 'Limite de baixo valor *',
				highRiskValueThreshold: 'Limite de alto valor de risco *',
				lowValueAmount: 'Valor de contrato baixo',
				highRiskValueAmount: 'Contrato de alto risco/valor',
				refresh: 'Atualizar na importação',
				forecastModule: 'Módulo de Forecast',
				usdConversionRate: 'Taxa de conversão para USD'
			},
			values: {
				false: 'Não',
				true: 'Sim'
			},
			actions: {
				cancel: 'Cancelar',
				update: 'Atualizar'
			},
			errors: {
				required: 'Obrigatório',
				negative: 'O valor deve ser superior a 0',
				maximum: 'O valor deve ser inferior a 1000'
			}
		}
	},
	forecasts: {
		page: {
			title: '{firstName} {lastName} visão geral do forecast',
			defaultTitle: 'Visão geral do forecast',
			breadcrumbs: {
				dashboard: 'Quadro da equipa',
				srOverview: 'Visão geral do Técnico Comercial',
				repPortfolio: '{firstName} {lastName} Portfolio',
				forecastsOverview: 'Visão geral do forecast',
				myPortfolio: 'O meu Portfolio'
			},
			list: {
				noResult: 'Nenhuma previsão encontrada',
				searchBar: 'Pesquisa por cliente ou número do contrato',
				headers: {
					contract: 'Contrato ({contractNumber})',
					status: 'Estado',
					units: 'Unidades ({unitsNumber})',
					unitsEmpty: 'Unidades',
					probability: 'Forecast',
					cancellationMonth: 'Mês de cancelamento'
				},
				information: {
					status: {
						TODO: 'SEM AÇÃO',
						IN_PROGRESS: 'EM CURSO',
						TO_BE_CANCELLED: 'PARA SER CANCELADO',
						TO_BE_AUTO_RENEWED: 'PARA SER PROLONGADO',
						RENEGOTIATED: 'RENEGOCIADO',
						UPGRADED: 'UPGRADE',
						DOWNGRADED: 'DOWNGRADE'
					},
					cancellationMonth: {
						old: 'Forecast desatualizado'
					}
				}
			}
		}, probability: {
			all: {
				label: 'Baixa probabilidade + Alta probabilidade'
			},
			[FORECAST_PROBABILITY.LOW]: {
				label: 'Baixa probabilidade'
			},
			[FORECAST_PROBABILITY.HIGH]: {
				label: 'Alta probabilidade'
			}
		},
		cancellationMonth: {
			all: {
				label: 'Todos os meses'
			},
			outdated: {
				label: 'Desatualizado'
			}
		}
	},
	import: {
		form: {
			country: {
				label: 'País',
				errors: {
					required: 'Obrigatório'
				}
			},
			file: {
				label: 'Ficheiro',
				empty: 'Sem ficheiro',
				button: 'Selecione um ficheiro',
				errors: {
					required: 'Obrigatório',
					wrongFormat: 'Formato de ficheiro errado'
				}
			},
			submit: 'Importar'
		},
		results: {
			header: {
				added: 'Número de utilizadores adicionados: {count}',
				errors: 'Número de erros: {count}'
			},
			success: 'Utilizador adicionado: {email}',
			errors: {
				regionMissing: 'ID da região em falta',
				branchNotInRegion: 'Delegação não encontrada em determinada região',
				branchNotFound: 'Delegação não encontrada',
				regionNotFound: 'Região não encontrada',
				branchMissing: 'ID da delegação em falta',
				profile: 'Perfil não encontrado',
				noRegionInCountry: 'Nenhuma região neste país',
				emailFormat: 'Formato de e-mail errado',
				emailMissing: 'Linha {line}: Email não encontrado',
				emailMissingExtended: 'Linha {line} - {name}: Email não encontrado',
				wrongProfile: 'Nenhuma correspondência com o perfil da base de dados'
			}
		}
	},
	crm: {
		btn: 'Run contract export to CRM Italy',
		success: 'Success of exporting contract to CRM Italy',
		error: 'Error while exporting contract to CRM Italy',
		loading: 'Exporting contract to CRM Italy...',
		date: {
			title: 'Date',
			helperText: 'Required format : dd-mm-yyyy',
			invalid: 'Invalid format'
		}
	}
})