import { flatten } from 'flat'
import { ACTION_TYPE, CUSTOMER_SATISFACTION, FORECAST_PROBABILITY, MANUALLY_CLOSED_STATUS, CONTRACT_MODULE, VIEW, MONTH } from '../enums'
import { LEVEL } from '../constants'

export default flatten(
	{
		title: 'RTV',
		menu: {
			retour: 'Torna all\'applicazione',
			countries: 'Gestisci i paesi',
			renegotiation: 'Schema Rinegoziazione',
			users: 'Gestisci gli utenti',
			import: 'Importa utenti'
		},
		renegotiation: {
			message: 'Modifica ogni cella in base alla politica di vendita nazionale nella tua lingua',
			risk_score: 'Punteggio di rischio contratto',
			price_score: 'Punteggio del canone contratto',
			dialog: {
				title: 'Scenario rinegoziazione per il punteggio rischio/valore {risk}/{price}',
				tip: 'Suggerimento',
				cancel: 'Cancella',
				update: 'Aggiorna'
			}
		},
		searchBar: 'Vai al contratto, venditore...',
		login: {
			button: 'Accedi con Microsoft',
			error: 'La tua utenza non consente l\'accesso all\'applicazione'
		},
		layout: {
			dashboard: 'Team Dashboard',
			srOverview: 'Panoramica venditore',
			contractsOverview: 'Panoramica contratti',
			portfolio: 'Mio portafoglio',
			actions: 'Panoramica azioni',
			forecasts: 'Panoramica previsioni',
			reporting: {
				label: 'Resoconto utente',
				teams: 'Teams',
				detailed: 'Resoconto mensile'
			},
			notifications: {
				help: {
					title: 'Aiuto necessario',
					noContract: 'Nessun contratto richiede aiuto',
					needHelp: '{firstName} {lastName} ha bisogno di aiuto per: {customerName} ({id})'
				},
				overdueContracts: {
					title: 'Contratti in ritardo',
					noContract: 'Nessun contratto in ritardo',
					contract: '{customerName} ({id}) in lista priorità da {nbMonths} mesi'
				},
				overdueActions: {
					title: 'Azioni in ritardo',
					noAction: 'Nessuna azione in ritardo',
					action: '{customerName} ({idContract}): azione pianificata il {deadline}'
				}
			},
			logout: 'Uscire'
		},
		search: {
			contracts: {
				title: 'Contratti',
				noResult: 'Nessun risultato trovato'
			},
			salesRepresentative: {
				title: 'Venditori',
				noResult: 'Nessun risultato trovato'
			}
		},
		pages: {
			selectCountry: {
				title: 'Seleziona il paese o l\'area',
				indicators: {
					withoutActions: 'Contratti senza azioni',
					withActions: 'Contratti senza azioni',
					closed: 'Contratti chiusi'
				},
				button: 'Team dashboard',
				seeReporting: 'Vedi report',
				backToHomePage: 'Torna alla pagina principale'
			},
			portfolio: {
				title: 'Mio portafoglio',
				breadcrumbs: 'Mio portafoglio',
				tabBar: {
					priority: 'Lista priorità',
					monthly: 'Elenco priorità di questo mese',
					all: 'Tutti i contratti',
					allDetails: 'Tutti i dettagli'
				}
			},
			contractsOverview: {
				title: 'Panoramica contratti',
				breadcrumbs: {
					dashboard: 'Team dashboard',
					contractsOverview: 'Panoramica contratti'
				},
				tabBar: {
					priority: 'Lista priorità',
					cancelled: 'Ultimi contratti cancellati',
					all: 'Tutti i contratti'
				},
				filters: {
					title: 'Filtri',
					help: 'Seleziona uno o più elementi in una o più colonne per visualizzare i contratti corrispondenti alla selezione.',
					reset: 'Reimposta filtri',
					apply: 'Applica',
					headers: {
						status: 'Stato contratto',
						classification: 'Classificazione',
						risk: 'Indicatore di Rischio',
						value: 'Indicatore di Valore',
						flags: 'Flag',
						endDate: 'Data fine contratto',
						riskTypology: 'Tipologia Rischio',
						customerFeedback: 'Riscontro Cliente'
					},
					status: {
						toDo: 'NESSUNA AZIONE',
						inProgress: 'IN CORSO',
						toBeCancelled: 'DA CANCELLARE',
						toBeExtended: 'DA RINNOVARE',
						renegociated: 'RINEGOZIATI',
						upgraded: 'RINEGOZIATI UP',
						downgraded: 'RINEGOZIATI DW'
					},
					classification: {
						high: 'Valore/Rischio Alto',
						low: 'Valore basso',
						manually: 'Aggiunto manualmente'
					},
					risk: {
						1: '1 - Basso',
						2: '2 - Basso',
						3: '3 - Medio',
						4: '4 - Medio',
						5: '5 - Alto'
					},
					value: {
						0: '0 - Negativo',
						1: '1 - Basso',
						2: '2 - Basso',
						3: '3 - Medio',
						4: '4 - Medio',
						5: '5 - Alto'
					},
					flags: {
						help: 'Bisogno di aiuto',
						overdue: 'In ritardo',
						actionsOverdue: 'Azioni in ritardo'
					},
					endDate: {
						outdated: 'Scaduto',
						short: '< 3 mesi',
						medium: '3 - 6 mesi',
						long: '> 6 mesi'
					},
					riskTypology: {
						field: 'Tecnico',
						sales: 'Commerciale'
					},
					customerFeedback: {
						cancellationLetter: 'Ricevuta lettera di cancellazione',
						claimLetter: 'Ricevuta lettera di reclamo (12 mesi)'
					}
				},
				sorts: {
					title: 'Ordina per',
					priority: {
						label: 'Priorità (impostazione predefinita)',
						labelShort: 'Priorità'
					},
					risk: {
						label: 'Rischio',
						labelShort: 'Rischio'
					},
					value: {
						label: 'Valore',
						labelShort: 'Valore'
					},
					endDate: {
						label: 'Data fine contratto',
						labelShort: 'Data fine'
					},
					unitsNumber: {
						label: 'Numero unità',
						labelShort: 'Unità'
					}
				},
				table: {
					searchBar: {
						classic: 'Ricerca per venditore, cliente o contratto',
						[CONTRACT_MODULE.FR]: 'Ricerca per venditore, supervisore, tecnico, cliente, contratto o indirizzo'
					},
					headers: {
						contract: {
							title: 'Contratto',
							cancelled: {
								title: 'Cancellato',
								tooltip: 'Contratto cancellato in CRM'
							},
							needHelp: {
								title: 'Bisogno di aiuto'
							}
						},
						status: {
							title: 'Stato azione',
							notPriority: {
								title: 'Contratto non prioritario',
								tooltip: 'Per gestire le azioni su questo contratto, vedere i dettagli del contratto e Aggiungi l\'azione all\'elenco delle priorità'
							}
						},
						actionsOpened: 'Azioni Aperte',
						valueIndicator: {
							title: 'Valore',
							tooltip: 'Indicatore di Valore: {indicator}/5 (0 = valore negativo)'
						},
						riskIndicator: {
							title: 'Rischio',
							tooltip: 'Indicatore di Rischio: {indicator}/5'
						},
						riskTypology: {
							title: 'Tipologia Rischio',
							flag: {
								sales: 'Rischio Commerciale',
								field: 'Rischio Tecnico'
							}
						},
						endDate: {
							title: 'Data fine contratto',
							tooltip: {
								desktop: 'Contratto prioritario non chiuso dopo 3 mesi',
								mobile: 'Contratto ancora aperto',
								contractEndTypology: 'Tipo fine contratto:',
								requestedCancellationDate: 'Data cancellazione richiesta:'
							}
						},
						unitsNumber: {
							title: 'Unità',
							tooltip: 'Prezzo vendita:'
						},
						advancedNoticePeriodDate: 'Scadenza disdetta'
					},
					noResult: 'Nessun contratto trovato',
					loading: 'Caricamento contratti',
					show: 'Mostra contratto'
				},
				otherContract: {
					title: 'Altri contratti',
					contracts: 'Contratti',
					salesRep: 'Venditori',
					portfolio: 'Mio portafoglio'
				},
				otherSR: {
					title: 'Atri venditori',
					noResult: 'Nessun altro venditore trovato',
					headers: {
						sales: 'Venditore',
						contracts: 'Contratti'
					}
				}
			},
			salePortfolioManager: {
				breadcrumbs: {
					dashboardRegional: 'Dashboard Area',
					dashboard: 'Dashboard Team',
					srOverview: 'Panoramica Venditore',
					srPortfolio: 'Portafoglio di {firstName} {lastName}',
					portfolio: 'Portafoglio'
				},
				title: {
					srPortfolio: 'Portafoglio di {firstName} {lastName}',
					portfolio: 'Portafoglio'
				}
			},
			contract: {
				comparison: {
					hide: 'Nascondi',
					show: 'Mostra',
					text: 'confronto canoni tra contratti simili',
					tooltip: 'Fornire indicazione della posizione del contratto rispetto ad altri contratti con caratteristiche simili (numero impianti, contratto e tipo di cliente...) nel portafoglio olandese.',
					chart: {
						price: 'Posizionamento del canone contratto',
						value: 'Posizionamento del valore contratto'
					},
					portfolioValue: 'Crescita del portafoglio (anno in corso):'
				},
				risk: {
					close: {
						title: 'Chiusura manuale rischio ',
						outcome: {
							title: 'Esito',
							to_be_autorenewed: {
								label: 'Da rinnovare',
								detail: '(Il contratto si rinnoverà automaticamente)'
							},
							to_be_cancelled: {
								label: 'Da cancellare',
								detail: '(Non è possibile mantenere il contratto e verrà annullato)'
							},
							renegotiated: {
								label: 'Rinegoziazione completata',
								detail: '(Rinegoziato con stesso valore contratto)'
							},
							upgraded: {
								label: 'Rinegoziazione UP completata',
								detail: '(Rinegoziato con aumento valore contratto)'
							},
							downgraded: {
								label: 'Rinegoziazione DW completata',
								detail: '(Rinegoziato con diminuzione valore contratto)'
							}
						},
						comment: 'Nota',
						cancel: 'Cancella',
						button: 'Chiusura manuale rischio ',
						errors: {
							required: 'Richiesto',
							mandatory: 'Informazione obbligatoria'
						}
					}
				},
				charts: {
					range: 'Intervallo: {rangeMin} {currency} - {rangeMax} {currency}',
					median: 'Media: {median} {currency}'
				},
				flags: {
					description: {
						ContractAge: 'Anzianità Contratto: contratto nel 20% dei contratti più vecchi',
						ContractDuration: 'Contratto senza rinnovo automatico: scadenza < 6 mesi',
						Public: 'Contratto Pubblico: Cliente pubblico',
						CustomerType: 'Tipologia Cliente: Cliente propenso alla rinegoziazione',
						NationalAccount: 'Cliente Nazionale: il cliente è nazionale',
						Recovery: 'Contratto originariamente recuperato: contratto inizialmente recuperato dal mercato',
						SellingPrice: 'Canone/Impianto elevato: contratto nel 20% dei contratti con il maggiore canone/impianto',
						ContractValue: 'Valore/Impianto elevato: contratto nel 20% dei contratti con il maggiore valore/impianto',
						Units: 'Numero elevato di impianti: contratto nel 20% dei contratti con maggior numero impianti',
						Rebel: 'Elevato numero impianti ribelli: contratto nel 20% dei contratti con maggior impianti ribelli negli ultimi 12 mesi',
						Callbacks: 'Numero elevato chiamate/impianto: contratto nel 20% dei contratti con il maggior numero chiamate/impianto negli ultimi 12 mesi',
						ResponseTime: 'Tempo di risposta medio elevato: contratto nel 20% dei contratti con i tempi di risposta medi impianto più alti negli ultimi 12 mesi',
						Availability: 'Bassa disponibilità impianto: contratto nel 20% dei contratti con più bassa disponibilità impianto negli ultimi 12 mesi',
						MissedMaintenance: 'Mancata manutenzione: contratto nel 20% dei contratti con manutenzione non eseguita negli ultimi 12 mesi',
						Trapped: 'Chiamate per passeggeri intrappolati: contratto nel 20% dei contratti con maggior numero chiamate per passeggeri intrappolati negli ultimi 12 mesi'
					},
					close: 'Chiudi'
				},
				breadcrumbs: {
					dashboardRegional: 'Dashboard Area',
					dashboard: 'Dashboard Team',
					srOverview: 'Panoramica venditore',
					srPortfolio: 'Portafoglio di {firstName} {lastName}',
					contract: '{name} ({contractId})',
					myPortfolio: 'Mio Portafoglio'
				},
				actions: {
					close: 'Chiudi Rischio',
					add: 'Aggiungi alla lista priorità',
					help: {
						title: 'Aiuto del Responsabile',
						tooltip: 'Chiedere aiuto al Capo Vendita / Filiale'
					}
				},
				information: {
					title: 'Informazioni & Gestione Rischio',
					status: {
						TODO: 'NESSUNA AZIONE',
						IN_PROGRESS: 'IN CORSO',
						TO_BE_CANCELLED: 'DA CANCELLARE',
						TO_BE_AUTO_RENEWED: 'DA RINNOVARE',
						RENEGOTIATED: 'RINEGOZIATO',
						UPGRADED: 'RINEGOZIATO UP',
						DOWNGRADED: 'RINEGOZIATO DW',
						CLOSED_AUTOMATICALLY: 'DA RINNOVARE'
					},
					headers: {
						contractValue: {
							title: 'Valore Contratto',
							tooltip: 'Valore O annuale + media valore T (< 5K€) negli ultimi 3 anni '
						},
						customerIdCrm: 'Numero Cliente',
						value: 'Valore',
						riskTypology: {
							title: 'Tipologia Rischio',
							flags: {
								sales: {
									title: 'Rischio Commerciale',
									name: 'Commerciale'
								},
								field: {
									title: 'Rischio Tecnico',
									name: 'Tecnico'
								}
							}
						},
						units: 'Unità',
						price: {
							title: 'Canone mensile',
							tooltip: 'Valore O annuale'
						},
						risk: 'Rischio',
						callbacks: 'Chiamate',
						endDate: 'Data fine contratto',
						invoicesValue: 'Valore credito',
						address: 'Indirizzo',
						renegotiationTip: {
							header: 'Regola rinegoziazione',
							dialogTitle: 'Regola rinegoziazione per questo contratto',
							noTip: 'Questo contratto non ha fatturazione ed è annullato',
							outlier: 'Questo contratto è molto specifico ed in caso di rinegoziazione è necessaria approvazione del responsabile'
						}
					},
					closed: {
						button: 'Riapertura rischio',
						text: 'Rischio chiuso il {date} da {firstName} {lastName}',
						textAuto: 'Rischio chiuso automaticamente il {date}'
					},
					comment: 'Nota',
					dialog: {
						error: {
							title: 'Il rischio non può essere chiuso',
							text: 'Tutte le azioni devono essere chiuse prima di chiudere il rischio.',
							close: 'Chiudi'
						},
						notAvailable: {
							title: 'Azione non disponibile',
							text: 'Solo il tuo responsabile può riaprire il rischio',
							cancel: 'Chiudi'
						},
						reOpen: {
							title: 'Conferma riapertura rischio',
							text: 'Riaprire il contratto? La causale di chiusura e le note associate saranno eliminate.',
							confirm: 'Riaprire il rischio'
						},
						addPriority: {
							title: 'Aggiungi all\'elenco delle priorità',
							text: 'Aggiungere il contratto all\'elenco delle priorità?',
							confirm: 'Aggiungi contratto',
							cancel: 'Chiudi'
						}
					},
					tabs: {
						tab: {
							details: 'Dettagli contratto',
							actions: 'Azioni',
							zeroAction: 'Crea la prima azione'
						},
						detailsTab: {
							moduleFrance: {
								advancedNoticePeriodDate: 'Scadenza periodo disdetta',
								contractCategory: 'Tipo contratto',
								requestedCancellationDate: 'Richiesta Data Cancellazione',
								claimLettersNb: 'Numero di reclami ricevuti',
								supervisorName: 'Nome supervisore',
								mechanicName: 'Nome tecnico',
								customerSatisfactionLvl: {
									title: 'Soddisfazione del cliente',
									[CUSTOMER_SATISFACTION.UNSATISFIED]: 'Insoddisfatto',
									[CUSTOMER_SATISFACTION.SATISFIED]: 'Soddisfatto'
								},
								contractEndTypology: 'Tipo fine contratto'
							},
							comments: {
								empty: 'Nessuna nota presente',
								lastModified: 'Ultima modifica il {date} di {firstName} {lastName}',
								updateComment: 'Aggiorna nota',
								managersTitle: 'Nota del Responsabile',
								srTitle: 'Nota del Venditore',
								dialog: {
									comment: 'Nota',
									cancel: 'Cancella',
									update: 'Aggiorna'
								}
							}
						}
					}
				},
				forecast: {
					title: 'Previsione Cancellazione',
					probability: {
						label: 'Probabilità',
						high: 'Alta Probabilità',
						low: 'Bassa Probabilità'
					},
					cancellationMonth: {
						label: 'Mese cancellazione'
					},
					save: 'Salva previsione',
					errors: {
						required: 'Richiesta',
						outdated: 'Previsione superata'
					},
					snackbar: {
						success: 'Previsione inserita',
						error: 'Errore nell\'inserimento'
					}
				}
			},
			dashboard: {
				title: 'Dashboard Team - {level}',
				branch: {
					table: {
						titleNational: 'Contratti aperti Paese',
						titleRegion: 'Contratti aperti Area',
						headers: {
							branchName: 'Filiale',
							todo: 'Contratti aperti senza azioni',
							inProgressCount: 'Contratti aperti con azioni',
							closedCount: 'Contratti chiusi'
						},
						noResult: 'Nessuna filiale trovata'
					}
				},
				region: {
					table: {
						title: 'Contratti aperti Aree',
						headers: {
							regionName: 'Area',
							todo: 'Contratti aperti senza azioni',
							inProgressCount: 'Contratti aperti con azioni',
							closedCount: 'Contratti chiusi'
						},
						noResult: 'Area non trovata'
					}
				},
				highRisk: {
					table: {
						title: 'Contratti Alto Valore / Alto Rischio',
						headers: {
							contract: 'Contratto',
							actionsOpened: 'Azioni aperte',
							valueIndicator: 'Valore',
							riskIndicator: 'Rischio'
						},
						tooltip: {
							value: 'Indicatore di valore : {indicator}/5 (0 = valore negativo)',
							risk: 'Indicatore di rischio : {indicator}/5'
						},
						noResult: 'Nessun contratto trovato'
					},
					button: 'Mostra tutti i contratti (incontro mensile)'
				},
				priorityOpenContracts: {
					title: 'Contratti aperti prioritari',
					indicators: {
						srNeedHelp: 'Venditore ha bisogno di aiuto',
						contractionWithActionsOverdue: 'Contratti con azioni in ritardo',
						contractsOverdue: 'Contratti in ritardo'
					},
					button: 'Mostra tutti i contratti',
					contracts: 'Contratti'
				},
				salesRep: {
					title: {
						national: 'Nazionale',
						openContracts: 'contratti aperti'
					},
					table: {
						headers: {
							lastName: 'Venditore',
							todo: 'Contratti aperti senza azioni',
							inProgress: 'Contratti aperti con azioni',
							closed: 'Contratti chiusi'
						},
						noResult: 'Nessun venditore trovato'
					},
					button: 'Mostra tutti i venditori'
				},
				topPriorityContracts: {
					title: 'Situazione contratti prioritari',
					tooltip: '{nb} contratti',
					type: {
						noAction: 'Nessuna azione',
						inProgress: 'In corso',
						manuallyClosed: 'Chiuso manualmente'
					}
				},
				manuallyClosedContracts: {
					title: 'Contratti prioritari chiusi',
					tooltip: 'Stato chiuso manualmente (lo stato di cancellazione del CRM non viene considerato)',
					headers: {
						all: 'Tutti i contratti',
						high: 'Valore/Rischio alto',
						low: 'Valore basso'
					},
					rows: {
						[MANUALLY_CLOSED_STATUS.TO_BE_CANCELLED]: {
							title: 'Da cancellare',
							tooltip: 'Nessuna possibilità di mantenere il contratto e verrà cancellato'
						},
						[MANUALLY_CLOSED_STATUS.TO_BE_AUTO_RENEWED]: {
							title: 'Da rinnovare',
							tooltip: 'Il contratto sarà rinnovato automaticamente'
						},
						[MANUALLY_CLOSED_STATUS.RENEGOTIATED]: {
							title: 'Rinegoziazione completata',
							tooltip: 'Contratto rinegoziato con valore invariato'
						},
						[MANUALLY_CLOSED_STATUS.UPGRADED]: {
							title: 'Rinegoziazione UP completata',
							tooltip: 'Contratto rinegoziato con valore aumentato'
						},
						[MANUALLY_CLOSED_STATUS.DOWNGRADED]: {
							title: 'Rinegoziazione DW completata',
							tooltip: 'Contratto rinegoziato con valore diminuito'
						}
					}
				}
			},
			srPortfolio: {
				openActions: {
					title: '{count} Azioni aperte sui miei contratti prioritari',
					plannedActions: {
						title: 'Pianificate',
						tooltip: '{count} azioni pianificate'
					},
					overdueActions: {
						title: 'In ritardo',
						tooltip: '{count} azioni in ritardo'
					},
					button: 'Mostra tutte le azioni'
				},
				priorityContracts: {
					title: 'I miei contratti prioritari aperti',
					noActionPlanned: 'Contratti senza azioni pianificate',
					highValue: 'Valore/Rischio Alto ',
					lowValue: 'Valore basso',
					manually: 'Aggiunto manualmente',
					nbContracts: '{nb} Contratti'
				},
				followUp: {
					title: 'Aggiornamento contratti',
					openActions: {
						title: '{count} azioni',
						planned: {
							title: 'Pianificate',
							tooltip: '{count} azioni pianificate'
						},
						overdue: {
							title: 'In ritardo',
							tooltip: '{count} azioni in ritardo'
						}
					},
					forecasts: {
						title: 'Previsione Cancellazioni',
						high: {
							title: 'AP',
							tooltip: '{count} AP'
						},
						low: {
							title: 'BP',
							tooltip: '{count} BP'
						}
					},
					button: 'Mostra tutto'
				}
			},
			srOverview: {
				title: {
					mobile: 'Panoramica venditore',
					desktop: 'Panoramica venditori (contratti prioritari)'
				},
				breadcrumbs: {
					dashboard: 'Dashboard Team',
					srOverview: 'Panoramica venditori'
				},
				row: {
					title: '{openContracts} contratti aperti, {totalContracts} totale ({openContractsPercentage}%)',
					subtitle: '{nbActions} senza azioni'
				},
				table: {
					headers: {
						lastName: 'Venditore',
						openContractsCount: {
							title: 'Contratti aperti',
							text: '{nbContracts} senza azioni'
						},
						openActionsCount: {
							title: 'Azioni rimanenti',
							text: '{nbActions} azioni in ritardo'
						},
						overdueCount: 'Contratti in ritardo',
						toBeCancelled: 'Da cancellare',
						toBeAutoRenewed: 'Da rinnovare',
						renegotiated: 'Rinegoziati',
						upgraded: 'Rinegoziati UP',
						downgraded: 'Rinegoziati DW',
						openActions: {
							title: 'Azioni aperte',
							text: '{nbActions} in ritardo'
						},
						overdueContracts: 'Contratti in ritardo'
					},
					noResult: 'Nessun venditore trovato'
				}
			},
			reporting: {
				month: {
					[MONTH.JANUARY]: 'Gennaio',
					[MONTH.FEBRUARY]: 'Febbraio',
					[MONTH.MARCH]: 'Marzo',
					[MONTH.APRIL]: 'Aprile',
					[MONTH.MAY]: 'Maggio',
					[MONTH.JUNE]: 'Giugno',
					[MONTH.JULY]: 'Luglio',
					[MONTH.AUGUST]: 'Agosto',
					[MONTH.SEPTEMBER]: 'Settembre',
					[MONTH.OCTOBER]: 'Ottobre',
					[MONTH.NOVEMBER]: 'Novembre',
					[MONTH.DECEMBER]: 'Dicembre'
				},
				filters: {
					level: {
						all: {
							[LEVEL.REGION]: 'Tutte le aree',
							[LEVEL.BRANCH]: 'Tutte le filiali',
							[LEVEL.SALES_REP]: 'Tutti i venditori',
							default: 'Perimetro'
						},
						[LEVEL.REGION]: 'Aree',
						[LEVEL.BRANCH]: 'Filiali',
						[LEVEL.SALES_REP]: 'Venditori',
						[LEVEL.USER]: 'Venditore',
						default: 'Perimetro'
					},
					year: 'Anno',
					view: {
						label: 'Modello da visualizzare',
						[VIEW.MONTHLY]: {
							performance: 'Visualizzazione mensile',
							adoption: 'Visualizzazione mensile'
						},
						[VIEW.YEAR_TO_DATE]: {
							performance: 'Da inizio anno',
							adoption: 'Media mensile'
						}
					}
				},
				tabBar: {
					adoption: 'Utilizzo',
					activity: 'Attività',
					performance: 'Prestazioni'
				},
				teams: {
					title: 'Teams',
					graph: {
						performance: {
							title: '% di contratti mantenuti VS contratti persi',
							legend: 'YTD %'
						},
						activity: {
							title: '% di contratti con azioni in ritardo',
							legend: 'YTD %'
						},
						adoption: {
							title: 'Panoramica sull\'utilizzo',
							legend: '% di contratti con azioni + contratti chiusi Vs totale contratti a rischio'
						}
					}
				},
				detailed: {
					title: 'Report mensile',
					perimeter: 'Perimetro',
					graph: {
						title: 'Contratti mantenuti',
						value: 'Valore',
						units: 'Unità'
					}
				},
				noResult: 'Nessun dato trovato',
				performance: {
					table: {
						tooltip: {
							savedContracts: 'Contratti chiusi come Rinegoziati e Rinnovati',
							lostContracts: 'Contratti chiusi come cancellati',
							savedContractsRate: 'Contratti mantenuti vs contratti persi',
							renegociatedContractsRate: 'Contratti rinegoziati vs totale contratti chiusi'
						},
						month: 'Mese',
						savedContracts: '<baliseToolTip>Contratti mantenuti {br} (Unità/valore)</baliseToolTip>',
						lostContracts: '<baliseToolTip>Contratti persi {br} (Unità/valore)</baliseToolTip>',
						savedContractsRate: '<baliseToolTip>Tasso contratti mantenuti {br} (Unità/valore)</baliseToolTip>',
						renegociatedContracts: 'Contratti rinegoziati {br} (Unità/valore)',
						renegociatedContractsRate: '<baliseToolTip>Tasso contratti rinegoziati {br} (Unità/valore)</baliseToolTip>',
						total: 'Totale'
					},
					tooltip: {
						good: '% di contratti mantenuti > 95%',
						bad: '% di contratti mantenuti < 80%'
					}
				},
				activity: {
					table: {
						day: 'Giorno',
						noActionsContracts: 'Contratti senza azioni {br} (Unità/valore)',
						contractsWithActions: 'Contratti con azioni {br} (Unità/valore)',
						contractsWithActionsRate: '% contratti con azioni',
						contractsWithOverdueActionsRate: '% contratti con azioni in ritardo',
						contractsWithHelpRequested: 'Contratti con necessità di aiuto del responsabile',
						total: 'Totale'
					},
					tooltip: 'Almeno il 50% dei contratti aperti dovrebbe avere un\'azione'
				},
				adoption: {
					table: {
						month: 'Mese',
						nbConnexion: 'Connessione a RTV (# di giorni)',
						nbChangementsActions: '# di azioni aggiornate o modificate',
						nbActionsOuvertes: '# di azioni aperte nel mese',
						nbChangementsDate: '# di scadenze azioni modificate',
						nbAjoutContrats: '# di contratti aggiunti manualmente',
						total: 'Totale'
					},
					tooltip: 'Modificate le scadenze di un numero elevato di azioni'
				},
				level: {
					[LEVEL.COUNTRY]: 'Paesi',
					[LEVEL.REGION]: 'Aree',
					[LEVEL.BRANCH]: 'Filiali',
					[LEVEL.SALES_REP]: 'Venditori',
					[LEVEL.USER]: 'Venditore'
				}
			}
		},
		valueIcon: {
			high: 'Contratto con priorità ad alto valore/alto rischio dal {month}/{year}',
			low: 'Contratto prioritario con basso valore dal {month}/{year}',
			manually: 'Aggiunto manualmente all\'elenco delle priorità'
		},
		router: {
			error: {
				title: 'Persa connessione API',
				content: 'Aggiorna la pagina o accedi nuovamente.'
			}
		},
		profiles: {
			ZONE_MANAGER: 'EMEA',
			NATIONAL: 'Nazionale',
			BRANCH_MANAGER: 'Capo Filiale',
			SALES_REPRESENTATIVE: 'Venditore',
			BRANCH_MANAGER_ASSISTANT: 'Assistente Capo Filiale',
			REGIONAL_MANAGER: 'Capo Area'
		},
		actionButton: {
			cancel: 'Cancella'
		},
		language: {
			fr: 'Francese',
			en: 'Inglese',
			cze: 'Ceco',
			de: 'Tedesco',
			sp: 'Spagnolo',
			pt: 'Portoghese',
			ita: 'Italiano'
		},
		action: {
			filters: {
				all: 'Tutte le azioni',
				open: 'Azioni aperte',
				closed: 'Azioni chiuse'
			},
			form: {
				select: 'Selezione azione...',
				comparison: 'Questo contratto è troppo specifico per il confronto prezzi',
				type: 'Tipo azione',
				deadline: 'Scadenza',
				description: 'Descrizione'
			},
			page: {
				title: 'Panoramica azioni di {firstName} {lastName}',
				defaultTitle: 'Panoramica azioni',
				breadcrumbs: {
					dashboard: 'Dashboard Team',
					srOverview: 'Panoramica venditore',
					repPortfolio: 'Portafoglio di {firstName} {lastName}',
					actionsOverview: 'Panoramica azioni',
					myPortfolio: 'Mio Portafoglio'
				},
				list: {
					noResult: 'Nessuna azione trovata',
					headers: {
						action: 'Azione',
						status: 'Stato',
						description: 'Descrizione',
						deadline: 'Scadenza'
					},
					tooltip: 'Azione in ritardo',
					button: 'Mostra contratto'
				},
				table: {
					searchBar: 'Ricerca per cliente, contratto, descrizione',
					filters: {
						type: 'Tipo azione'
					}
				}
			},
			contract: {
				searchBar: 'Cerca descrizione azione',
				addButton: 'Aggiungi nuova azione',
				noResult: 'Nessuna azione trovata',
				openBy: 'Aperta da {firstName} {lastName}',
				closedOn: 'Chiusa il {date} da {firstName} {lastName}',
				lastModified: 'Ultima modifica il {date} di {firstName} {lastName}',
				button: {
					update: 'Aggiorna azione',
					close: 'Chiudi azione'
				},
				confirmation: {
					title: 'Conferma chiusura azione',
					content: 'Chiudi azione ?',
					buttonLabel: 'Chiudi azione'
				},
				new: {
					title: 'AZIONE',
					buttonLabel: 'Crea azione',
					cancel: 'Cancella'
				},
				update: {
					title: 'AZIONE',
					buttonLabel: 'Salva'
				},
				deadline: 'Scadenza'
			},
			status: {
				all: 'Tutte le azioni',
				open: {
					label: 'Azioni aperte',
					chipLabel: 'Aperte'
				},
				closed: {
					label: 'Azioni chiuse',
					chipLabel: 'Chiuse'
				}
			},
			type: {
				all: {
					label: 'Tutte'
				},
				[ACTION_TYPE.FIRST_CALL]: {
					label: 'Prima chiamata',
					defaultDescription: 'Chiamare il cliente per verificare soddisfazione e contesto'
				},
				[ACTION_TYPE.CUSTOMER_MEETING]: {
					label: 'Incontro con cliente',
					defaultDescription: 'Incontrare il cliente per migliorare il rapporto e verificare il contesto contrattuale'
				},
				[ACTION_TYPE.REPORT_TO_CUSTOMER]: {
					label: 'Informa il cliente',
					defaultDescription: 'Condividere le informazioni con il cliente per migliorare la soddisfazione'
				},
				[ACTION_TYPE.PROACTIVE_PROPOSAL]: {
					label: 'Offerta proattiva',
					defaultDescription: 'Fare offerta per rinnovare il contratto prima della scadenza'
				},
				[ACTION_TYPE.RENEGOTIATION]: {
					label: 'Rinegoziazione',
					defaultDescription: 'Fare offerta per nuovo contratto richiesto dal cliente'
				},
				[ACTION_TYPE.DOWNSELL]: {
					label: 'Togliere i servizi aggiuntivi',
					defaultDescription: 'Proporre downgrading del contratto per rimanere competitivi'
				},
				[ACTION_TYPE.UPSELL]: {
					label: 'Vendere i servizi aggiuntivi',
					defaultDescription: 'Proporre upgrading del contratto e del prezzo per aumentare il valore'
				},
				[ACTION_TYPE.SEND_MECHANIC_TO_JOBSITE]: {
					label: 'Mandare il tecnico sull\'impianto',
					defaultDescription: 'Risolvere il problema tecnico dell\'impianto'
				},
				[ACTION_TYPE.SEND_SUPERVISOR_TO_JOBSITE]: {
					label: 'Mandare il supervisore sull\'impianto',
					defaultDescription: 'Controllare l\'impianto per evitare il rischio di chiamata'
				},
				[ACTION_TYPE.CONTACT_SUPERVISOR_MECHANIC]: {
					label: 'Contattare supervisore/tecnico',
					defaultDescription: 'Capire la situazione tecnica dell\'impianto'
				},
				[ACTION_TYPE.OTHER]: {
					label: 'Altro'
				},
				[ACTION_TYPE.CUSTOMER_CLAIM]: {
					label: 'Gestire reclamo cliente'
				},
				[ACTION_TYPE.CANCELLATION_REQUEST]: {
					label: 'Gestire disdetta'
				}
			}
		},
		user: {
			new: {
				title: 'Creazione utente',
				buttonLabel: 'Creare'
			},
			update: {
				title: 'Modifica utente',
				buttonLabel: 'Aggiornare'
			},
			form: {
				errors: {
					required: 'Richiesto',
					invalidFormat: 'Formato non valido',
					max255char: 'Massimo 255 caratteri',
					emailExists: 'Questa email è già presente.',
					emailInactive: 'Questa email è attualmente inattiva.'
				},
				fields: {
					lastName: 'Cognome *',
					firstName: 'Nome *',
					email: 'Email *',
					profile: 'Profilo',
					country: 'Paese',
					branches: 'Filiale(i)',
					region: 'Area'
				}
			},
			table: {
				headers: {
					lastName: 'Cognome',
					firstName: 'Nome',
					email: 'Email',
					branch: 'Filiale(i)',
					profile: 'Profilo',
					country: 'Paese',
					region: 'Area'
				},
				actions: {
					reactivate: 'Riattivare',
					modify: 'Modificare',
					deactivate: 'Disabilitare'
				},
				filters: {
					searchBar: 'Filtra per nome utente, email o filiale',
					select: {
						placeholder: 'Profilo',
						all: 'Tutti i profili',
						national: 'Nazionale',
						branchManager: 'Capo Filiale',
						branchManagerAssistant: 'Assistente Capo Filiale',
						salesRepresentative: 'Venditore',
						regionalManager: 'Capo Area'
					},
					switch: 'Mostra utenti disattivati',
					reset: 'Rimuovi filtri'
				}
			},
			menu: {
				myAccount: 'Mia utenza',
				quit: 'Esci'
			}
		},
		errors: {
			mandatory: 'Campo obbligatorio',
			onlyFutureDate: 'Solo data futura'
		},
		countries: {
			table: {
				headers: {
					name: 'Nome',
					lowValueThreshold: 'Soglia valore basso',
					highRiskValueThreshold: 'Soglia valore di rischio elevato',
					lowValueAmount: 'Importo contratto a basso valore',
					highRiskValueAmount: 'Importo contratto ad alto valore di rischio',
					refresh: 'Aggiornamento sull\'importazione',
					forecastModule: 'Modulo previsioni'
				},
				actions: {
					modify: 'Modificato da'
				},
				values: {
					false: 'No',
					true: 'Si'
				}
			},
			dialog: {
				title: 'Modifica del paese',
				labels: {
					name: 'Nome *',
					lowValueThreshold: 'Soglia valore basso *',
					highRiskValueThreshold: 'Soglia valore di rischio elevato *',
					lowValueAmount: 'Importo contratto a basso valore *',
					highRiskValueAmount: 'Importo contratto ad alto valore di rischio *',
					refresh: 'Aggiornamento sull\'importazione *',
					forecastModule: 'Modulo previsioni *',
					usdConversionRate: 'Tasso conversione in $USD'
				},
				values: {
					false: 'No',
					true: 'Si'
				},
				actions: {
					cancel: 'Cancella',
					update: 'Aggiorna'
				},
				errors: {
					required: 'Richiesto',
					negative: 'Il valore deve essere maggiore di 0',
					maximum: 'Il valore deve essere inferiore a 1000'
				}
			}
		},
		forecasts: {
			page: {
				title: 'Panoramica previsioni di {firstName} {lastName}',
				defaultTitle: 'Panoramica previsioni',
				breadcrumbs: {
					dashboard: 'Dashboard Team',
					srOverview: 'Panoramica Venditore',
					repPortfolio: 'Portafoglio di {firstName} {lastName}',
					forecastsOverview: 'Panoramica previsioni',
					myPortfolio: 'Mio Portafoglio'
				},
				list: {
					noResult: 'Previsione non trovata',
					searchBar: 'Ricerca per cliente o contratto',
					headers: {
						contract: 'Contratto ({contractNumber})',
						status: 'Stato',
						units: 'Unità ({unitsNumber})',
						unitsEmpty: 'Unità',
						probability: 'Previsione',
						cancellationMonth: 'Mese di cancellazione'
					},
					information: {
						status: {
							TODO: 'NESSUNA AZIONE',
							IN_PROGRESS: 'IN LAVORAZIONE',
							TO_BE_CANCELLED: 'DA CANCELLARE',
							TO_BE_AUTO_RENEWED: 'DA RINNOVARE',
							RENEGOTIATED: 'RINEGOZIATO',
							UPGRADED: 'RINEGOZIATO UP',
							DOWNGRADED: 'RINEGOZIATO DW'
						},
						cancellationMonth: {
							old: 'Previsione superata'
						}
					}
				}
			},
			probability: {
				all: {
					label: 'BP + AP'
				},
				[FORECAST_PROBABILITY.LOW]: {
					label: 'BP'
				},
				[FORECAST_PROBABILITY.HIGH]: {
					label: 'AP'
				}
			},
			cancellationMonth: {
				all: {
					label: 'Tutti i mesi'
				},
				outdated: {
					label: 'Superato'
				}
			}
		},
		import: {
			form: {
				country: {
					label: 'Paese',
					errors: {
						required: 'Richiesto'
					}
				},
				file: {
					label: 'File',
					empty: 'Nessun file',
					button: 'Selezione un file',
					errors: {
						required: 'Richiesto',
						wrongFormat: 'Formato file errato'
					}
				},
				submit: 'Importa'
			},
			results: {
				header: {
					added: 'Numero di utenti aggiunti: {count}',
					errors: 'Numero di errori: {count}'
				},
				success: 'Utente aggiunto: {email}',
				errors: {
					regionMissing: 'Riferimento Area mancante',
					branchNotInRegion: 'Filiale non trovata nell\'Area',
					branchNotFound: 'Filiale non trovata',
					regionNotFound: 'Area non trovata',
					branchMissing: 'Riferimento Filiale mancante',
					profile: 'Profilo non trovato',
					noRegionInCountry: 'Nessuna Area in questo paese',
					emailFormat: 'Formato email errato',
					emailMissing: 'Linea {line}: Email non trovata',
					emailMissingExtended: 'Linea {line} - {name}: Email non trovata',
					wrongProfile: 'Nessuna corrispondenza con il profilo del database'
				}
			}
		},
		crm: {
			btn: 'Esegui l\'esportazione contratti verso CRM Italia',
			success: 'Esportazione contratti eseguita correttamente verso CRM Italia',
			error: 'Errore durante l\'esportazione contratti in CRM Italia',
			loading: 'Esportazione contratti in CRM Italia...',
			date: {
				title: 'Data',
				helperText: 'Formato richiesto: dd-MM-aaaa',
				invalid: 'Formato non valido'
			}
		}
	}
)